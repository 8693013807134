import { Helmet } from "react-helmet-async";
import React, { useState, useEffect } from "react";
import {
  Grid,
  Breadcrumbs,
  Link,
  Container,
  Typography,
  Box,
  Stack,
  Snackbar,
  Paper,
  CircularProgress,
  Backdrop,
  TextField,
  InputAdornment,
} from "@mui/material";
import { motion } from "framer-motion";
import useResponsive from "../hooks/useResponsive";
import { getForwardComissionSummaryForUser } from "../actions/api_calls";

import { AppConversionRates } from "../sections/@dashboard/app";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { NumericFormat } from "react-number-format";
import { blue } from "@mui/material/colors";

const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  //color: theme.palette.text.secondary,
  flexGrow: 1,
}));

let easing = [0.6, -0.05, 0.01, 0.99];
const animate = {
  opacity: 1,
  y: 0,
  transition: {
    duration: 0.6,
    ease: easing,
    delay: 0.16,
  },
};

const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
      prefix="£ "
    />
  );
});

const ForwardCommission = () => {
  //xs, extra-small: 0px
  // sm, small: 600px
  // md, medium: 900px
  // lg, large: 1200px
  // xl, extra-large: 1536px
  const isMobile = useResponsive("down", "sm");

  const navigate = useNavigate();

  const [isPageLoading, setPageLoading] = useState(true);
  const [userCommissionInfo, setUserCommissionInfo] = useState();
  const [totalCommission, setTotalComission] = useState(0);
  const [monthlyTarget, setMonthlyTarget] = useState(0);

  const [displayError, setDisplayError] = useState(false);

  useEffect(() => {
    setPageLoading(true);

    getForwardComissionSummaryForUser(setUserCommissionInfo);
  }, []);

  useEffect(() => {
    if (userCommissionInfo !== "" && userCommissionInfo !== undefined) {
      if (userCommissionInfo?.error && userCommissionInfo?.errorCode === 401) {
        localStorage.removeItem("isAuth");
        localStorage.removeItem("userDetails");
        localStorage.removeItem("authToken");
        navigate("/");
      } else if (
        userCommissionInfo?.error &&
        userCommissionInfo?.errorCode !== 401
      ) {
        setDisplayError(true);
      } else {
        setTotalComission(userCommissionInfo?.total_comms || 0);
      }

      setPageLoading(false);
    }
  }, [userCommissionInfo]);

  const getForwardCommsGraphData = () => {
    let graphData = userCommissionInfo?.monthly_comms
      ?.sort((a, b) => {
        return a?.year_mon?.getTime() - b?.year_mon?.getTime();
      })
      .map((s) => {
        return {
          label: format(s.year_mon, isMobile ? "MMM yy" : "MMMM,yyyy"),
          value: s.forward_comms,
        };
      });
    return graphData;
  };

  return (
    <>
      <Container maxWidth="lg">
        <Helmet>
          <title> ForwardCommission | NJT </title>
        </Helmet>

        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/user/home">
            Home
          </Link>

          <Typography color="text.primary">
            Forward Commission Dashboard
          </Typography>
        </Breadcrumbs>

        <Grid container sx={{ margin: 1, display: "flex", width: `100%` }}>
          <Typography variant="h4" sx={{ marginTop: 1 }} gutterBottom>
            Forward Commission
          </Typography>
        </Grid>
        <Grid container sx={{ margin: 1, display: "flex", width: `100%` }}>
          <Typography color="blue" sx={{ fontSize: 12 }}>
            Please note, the Forward Commission is the total profit on your
            bookings before any commission threshold splits, PIT payments or
            Merchant Fee (card charge) deductions.
          </Typography>
        </Grid>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={userCommissionInfo?.error && displayError}
          onClose={() => setDisplayError(false)}
          message={userCommissionInfo?.errorMessage}
          autoHideDuration={3000}
        />

        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isPageLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Grid container sx={{ marginTop: 0, display: "flex" }}>
          <Item>
            <Stack direction={"row"} spacing={1} marginTop={0}>
              <Grid item xs={4} container spacing={2} alignItems="center">
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle1"
                    sx={{ margin: 0, textAlign: "center" }}
                  >
                    {"Total Forward Commission"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={isMobile ? 8 : 4} container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    
                    type="text"
                    value={`${new Intl.NumberFormat("en-GB").format(
                      Math.round(totalCommission)
                    )}`}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">£</InputAdornment>
                      ),
                      readOnly: true,
                    }}
                    helperText={isMobile? "Depatures from current month" :"Value indicates the total forward comms for the depatures from current month onwards"}
                  />
                </Grid>
              </Grid>
            </Stack>
          </Item>
        </Grid>
        <Grid container sx={{ marginTop: 0, display: "flex" }}>
          <Item>
            <Stack direction={"row"} spacing={1} marginTop={0}>
              <Grid item xs={4} container spacing={2} alignItems="center">
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle1"
                    sx={{ margin: 0, textAlign: "center" }}
                  >
                    {"Monthly Target "}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ margin: 0, textAlign: "center" }}
                  >
                    {"(Red Line in graph)"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={isMobile ? 8 : 4} container spacing={2}>
                <Grid item xs={12} id="txt">
                  <TextField
                    fullWidth
                    type="text"
                    value={monthlyTarget}
                    autoComplete="monthlyTarget"
                    onChange={(event) => {
                      setMonthlyTarget(
                        event.target.value === ""
                          ? 0
                          : Number(event.target.value)
                      );
                    }}
                    InputProps={{
                      inputComponent: NumericFormatCustom,
                    }}
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  />
                </Grid>
                {/* <Grid item xs={4} >
                <Box
                component={motion.div}
                initial={{ opacity: 0, y: 20 }}
                animate={animate}
              >


                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isPageLoading}
                  onClick={()=>}
                >
                  {isPageLoading ? "loading..." : "Submit"}
                </LoadingButton>
              </Box>

                </Grid> */}
              </Grid>
            </Stack>
          </Item>
        </Grid>

        <Grid container sx={{ marginTop: 0, display: "flex" }}>
          <Box
            component={motion.div}
            initial={{ opacity: 0, y: 20 }}
            animate={animate}
            sx={{ marginTop: 2, width: `100%` }}
          >
            {userCommissionInfo?.monthly_comms && (
              <Grid item xs={12} md lg>
                <AppConversionRates
                  //title="Commission"
                  target={monthlyTarget}
                  isMobile={isMobile}
                  // chartData={[
                  //   { label: 'Italy', value: -100 },
                  //   { label: 'Japan', value: 430 },
                  //   { label: 'China', value: 448 },
                  //   { label: 'Canada', value: 470 },
                  //   { label: 'France', value: 540 },
                  //   { label: 'Germany', value: 580 },
                  //   { label: 'South Korea', value: 690 },
                  //   { label: 'Netherlands', value: 1100 },
                  //   { label: 'United States', value: 1200 },
                  //   { label: 'United Kingdom', value: 1380 },
                  // ]}
                  chartData={getForwardCommsGraphData() || []}
                />
              </Grid>
            )}
          </Box>
        </Grid>
      </Container>
    </>
  );
};

export default ForwardCommission;
