import { BrowserRouter, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
// routes
import Router from './routes';
import { useState } from 'react';
// theme
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import DashboardAppPage from './pages/DashboardAppPage';
import DashboardLayout from './layouts/dashboard';
import ThemeProvider from './theme';
// components
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';

// ----------------------------------------------------------------------


export default function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <ThemeProvider>
          <ScrollToTop />
          <StyledChart />
          {/* <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/signup" element={<DashboardAppPage />} />
            <Route path="/404" element={<Page404 />} />
            <Route
              path="/"
              element={
                isAuthenticated ? (
                  <DashboardAppPage />
                ) : (
                  <Navigate to="/login" state={{ from: location }} replace />
                )
              }
            />
          </Routes> */}
           <Router />
        </ThemeProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}
