import { Helmet } from "react-helmet-async";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import useResponsive from "../hooks/useResponsive";
import {
  Card,
  Box,
  Link,
  Stack,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Container,
  Typography,
  Backdrop,
  CircularProgress,
  Snackbar,
  Grid,
  Breadcrumbs,
  ToggleButton,
  Slider,
  ToggleButtonGroup,
  Divider,
  TableRow,
  TableHead,
  Table,
  TableContainer,
  TableBody,
} from "@mui/material";

import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { getOverAllSummaryForUser } from "../actions/api_calls";
import { motion } from "framer-motion";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { NumericFormat } from "react-number-format";

let easing = [0.6, -0.05, 0.01, 0.99];
const animate = {
  opacity: 1,
  y: 0,
  transition: {
    duration: 0.6,
    ease: easing,
    delay: 0.16,
  },
};
let formatter = Intl.NumberFormat("en", { notation: "compact" });
const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  //color: theme.palette.text.secondary,
  flexGrow: 1,
}));

const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
  props,
  ref
) {
  const { suffix, prefix, onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
      prefix={prefix}
      suffix={suffix}
    />
  );
});

const TargetCalculator = () => {
  const [selectedOverviewToggle, setOverviewToggleSelect] = useState("1Y");

  const navigate = useNavigate();

  const [isPageLoading, setPageLoading] = useState(true);
  const [userInfo, setUserInfo] = useState();
  const [displayError, setDisplayError] = useState(false);
  const [selectedMargin, setSelectedMargin] = useState("abm");
  const [selectedAvgValue, setSelectedAvgValue] = useState("abv");

  const [avgBookingValue, setavgBookingValue] = useState();
  const [avgBookingMargin, setavgBookingMargin] = useState();
  const [conversionRate, setConversionRate] = useState(10);
  const [opportunities, setOpportunities] = useState(0);
  const [totalProfit, setTotalProfit] = useState(0);
  const [profitClicked, setProfitClicked] = useState(false);

  const handleOverviewToggleSelect = (event, newTime) => {
    if (newTime !== null) {
      let selectedToggle = newTime.replace(selectedOverviewToggle, "");
      setOverviewToggleSelect(selectedToggle);
    }
  };

  useEffect(() => {
    getOverAllSummaryForUser(setUserInfo);
  }, []);

  useEffect(() => {
    calcFunc(totalProfit, calculateTotalProfit, setTotalProfit);
  }, [avgBookingValue, avgBookingMargin, conversionRate]);

  const calcFunc = (value, calulator, setter) => {
    let oldvalue = value;
    let calValue = calulator();
    if (oldvalue !== calValue) {
      setter(calValue);
      return calValue;
    }
    return 0;
  };

  const calculateTotalProfit = () => {
    if (
      opportunities > 0 &&
      conversionRate > 0 &&
      avgBookingMargin > 0 &&
      avgBookingValue > 0
    ) {
      return Math.round((calculateTotalSales() * avgBookingMargin) / 100);
    } else {
      return 0;
    }
  };

  const calculateTotalProfitWithNewOpportunities = (newOpportunities) => {
    if (
      newOpportunities > 0 &&
      conversionRate > 0 &&
      avgBookingMargin > 0 &&
      avgBookingValue > 0
    ) {
      return Math.round(
        (((newOpportunities * conversionRate) / 100) *
          avgBookingValue *
          avgBookingMargin) /
          100
      );
    } else {
      return 0;
    }
  };

  const calculateTotalSales = () => {
    if (opportunities > 0 && conversionRate > 0 && avgBookingValue > 0) {
      return calculateTotalBooking() * avgBookingValue;
    } else {
      return 0;
    }
  };

  const calculateTotalBooking = () => {
    if (opportunities > 0 && conversionRate > 0) {
      return (opportunities * conversionRate) / 100;
    } else {
      return 0;
    }
  };

  const calculateOpportunities = () => {
    if (
      totalProfit > 0 &&
      conversionRate > 0 &&
      avgBookingMargin > 0 &&
      avgBookingValue > 0
    ) {
      return Math.round(
        (((totalProfit * 100) / avgBookingMargin / avgBookingValue) * 100) /
          conversionRate
      );
    } else {
      return 0;
    }
  };

  const calculateOpportunitiesWithNewTotalProfit = (newTotalProfit) => {
    if (
      newTotalProfit > 0 &&
      conversionRate > 0 &&
      avgBookingMargin > 0 &&
      avgBookingValue > 0
    ) {
      return Math.round(
        (((newTotalProfit * 100) / avgBookingMargin / avgBookingValue) * 100) /
          conversionRate
      );
    } else {
      return 0;
    }
  };

  useEffect(() => {
    if (userInfo !== "" && userInfo !== undefined) {
      if (userInfo?.error && userInfo?.errorCode === 401) {
        localStorage.removeItem("isAuth");
        localStorage.removeItem("userDetails");
        localStorage.removeItem("authToken");
        navigate("/");
      } else if (userInfo?.error && userInfo?.errorCode !== 401) {
        setDisplayError(true);
      } else {
        setavgBookingValue(Math.round(userInfo?.abv_lifetime || 0));
        setavgBookingMargin(
          Math.round((userInfo?.lifetime_margin || 0) * 10) / 10
        );
      }

      setPageLoading(false);
    }
  }, [userInfo]);

  //xs, extra-small: 0px
  // sm, small: 600px
  // md, medium: 900px
  // lg, large: 1200px
  // xl, extra-large: 1536px
  const isMobile = useResponsive("down", "sm");

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  function formatCellValueforMobile(value) {
    return isMobile
      ? formatter.format(Math.round(value))
      : new Intl.NumberFormat("en-GB").format(Math.round(value));
  }

  function createData(name, value, prefix) {
    let mf = mutiplyingFactor();
    return {
      name,
      day: `${prefix ? prefix : ""} ${formatCellValueforMobile(
        value * mf.day
      )}`,
      week: `${prefix ? prefix : ""} ${formatCellValueforMobile(
        value * mf.week
      )}`,
      month: `${prefix ? prefix : ""} ${formatCellValueforMobile(
        value * mf.month
      )}`,
      year: `${prefix ? prefix : ""} ${formatCellValueforMobile(
        value * mf.year
      )}`,
    };
  }

  function mutiplyingFactor() {
    if (selectedOverviewToggle === "1Y") {
      return { day: 1 / 365, month: 1 / 12, week: 1 / 52, year: 1 };
    }
    if (selectedOverviewToggle === "1D") {
      return { day: 1, month: 30, week: 7, year: 365 };
    }
    if (selectedOverviewToggle === "1W") {
      return { day: 1 / 7, month: 4, week: 1, year: 52 };
    }
    if (selectedOverviewToggle === "1M") {
      return { day: 1 / 30, month: 1, week: 1 / 4, year: 12 };
    }
  }

  const rows = [
    createData("Profit", totalProfit, "£ "),
    createData("Sales", calculateTotalSales(), "£ "),
    createData("Booking", calculateTotalBooking()),
    createData("Opportunities", calculateOpportunities()),
  ];

  return (
    <Container maxWidth="lg">
      <Helmet>
        <title> Target Calculator | NJT </title>
      </Helmet>

      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/user/home">
          Home
        </Link>
        <Link underline="hover" color="inherit" href="/user/targetCalculator">
          Target Calculator
        </Link>
        <Typography>New</Typography>
      </Breadcrumbs>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={userInfo?.error && displayError}
        onClose={() => setDisplayError(false)}
        message={userInfo?.errorMessage}
        autoHideDuration={3000}
      />
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          display: "flex",
        }}
        open={isPageLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container sx={{ marginTop: 1, display: "flex", width: `100%` }}>
        <Typography variant="h4" sx={{ marginTop: 1 }} gutterBottom>
          Target Calculator
        </Typography>
      </Grid>
      <Grid container>
        <Card sx={{ margin: 0, display: "flex", width: "100%" }}>
          <Box
            component={motion.div}
            initial={{ opacity: 0, y: 20 }}
            animate={animate}
            sx={{ margin: 1, width: `100%` }}
          >
            <Stack direction="column" useFlexGap>
              {isMobile ? (
                <Item>
                  <Stack direction={{ xs: "column", sm: "row" }}>
                    <Item>
                      <Stack direction="row">
                        <Item>
                          <Typography
                            variant="subtitle2"
                            sx={{ margin: 1, textAlign: "left", maxWidth: 120 }}
                          >
                            {"Calculate for"}
                          </Typography>
                        </Item>
                        <Item>
                          <ToggleButtonGroup
                            value={selectedOverviewToggle}
                            onChange={handleOverviewToggleSelect}
                            aria-label="time"
                            exclusive
                            sx={{ display: "flex" }}
                            fullWidth
                          >
                            <ToggleButton value="1D" aria-label="1D">
                              1D
                            </ToggleButton>
                            <ToggleButton value="1W" aria-label="1W">
                              1W
                            </ToggleButton>
                            <ToggleButton value="1M" aria-label="6M">
                              1M
                            </ToggleButton>
                            <ToggleButton value="1Y" aria-label="1Y">
                              1Y
                            </ToggleButton>
                          </ToggleButtonGroup>
                        </Item>
                      </Stack>
                    </Item>
                  </Stack>
                </Item>
              ) : (
                <Item>
                  <Stack direction={{ xs: "column", sm: "row" }} spacing={1}>
                    <Item>
                      <Typography
                        variant="subtitle1"
                        sx={{ marginTop: 1, textAlign: "center" }}
                      >
                        {"Calculate for"}
                      </Typography>
                    </Item>
                    <Item>
                      <ToggleButtonGroup
                        value={selectedOverviewToggle}
                        onChange={handleOverviewToggleSelect}
                        aria-label="time"
                        exclusive
                        sx={{ display: "flex" }}
                      >
                        <ToggleButton value="1D" aria-label="1D">
                          1 Day
                        </ToggleButton>
                        <ToggleButton value="1W" aria-label="1W">
                          1 Week
                        </ToggleButton>
                        <ToggleButton value="1M" aria-label="6M">
                          1 Month
                        </ToggleButton>
                        <ToggleButton value="1Y" aria-label="1Y">
                          1 Year
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </Item>
                  </Stack>
                </Item>
              )}
              <Divider />
              {isMobile ? (
                <Item>
                  <Stack direction={{ xs: "column", sm: "row" }}>
                    <Item>
                      <Stack direction="row">
                        <Item>
                          <FormControl sx={{ width: 120 }} fullWidth>
                            <InputLabel id="Average-Booking-Value-label">
                              ABV
                            </InputLabel>
                            <Select
                              id="Average-Booking-Value-id"
                              labelId="Average-Booking-Value-label"
                              label="Avg. Booking Value"
                              onChange={(event) => {
                                setSelectedAvgValue(event.target.value);
                                if (event.target.value === "default") {
                                  setavgBookingValue(Math.round(4200));
                                } else if (event.target.value === "abv") {
                                  setavgBookingValue(
                                    Math.round(userInfo?.abv_lifetime)
                                  );
                                }
                              }}
                              fullWidth
                              defaultValue={"abv"}
                              value={selectedAvgValue}
                            >
                              <MenuItem value="abv">Individual ABV</MenuItem>
                              <MenuItem value="default">Default</MenuItem>
                              <MenuItem value="custom">Custom</MenuItem>
                            </Select>
                          </FormControl>
                        </Item>

                        <Item>
                          <TextField
                            fullWidth
                            type="text"
                            value={avgBookingValue || 0}
                            autoComplete="avgBookingValue"
                            label="Avg. Booking Value"
                            onChange={(event) => {
                              if (
                                selectedAvgValue !== "custom" &&
                                Math.round(userInfo?.abv_lifetime) !==
                                  Math.round(Number(event.target.value)) &&
                                Math.round(4200) !==
                                  Math.round(Number(event.target.value))
                              ) {
                                setSelectedAvgValue("custom");
                              }
                              setavgBookingValue(
                                event.target.value === ""
                                  ? 0
                                  : Math.round(Number(event.target.value))
                              );
                            }}
                            InputProps={{
                              inputComponent: NumericFormatCustom,
                            }}
                            inputProps={{
                              inputMode: "numeric",
                              pattern: "[0-9]*",
                              prefix: "£ ",
                            }}
                          />
                        </Item>
                      </Stack>
                    </Item>
                    <Item sx={{ marginRight: 2 }}>
                      <Slider
                        min={0}
                        max={25000}
                        value={avgBookingValue || 0}
                        onChange={(event, newValue) =>
                          setavgBookingValue(newValue)
                        }
                        aria-labelledby="input-slider1"
                        step={1}
                        marks={[
                          {
                            value: 0,
                            label: `£ 0`,
                          },
                          {
                            value: 25000,
                            label: `£ ${formatter.format(25000)}`,
                          },
                        ]}
                        valueLabelDisplay="auto"
                        getAriaValueText={(value) => {
                          return `£  ${formatter.format(value)}`;
                        }}
                        valueLabelFormat={(value) => {
                          return `£  ${formatter.format(value)}`;
                        }}
                      />
                    </Item>
                  </Stack>
                </Item>
              ) : (
                <Item>
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    spacing={1}
                    marginTop={0}
                  >
                    <Grid item xs={8} container spacing={1}>
                      <Grid item xs={12}>
                        <FormControl sx={{ m: 1, minWidth: 120 }} fullWidth>
                          <InputLabel id="Average-Booking-Value-label">
                            ABV
                          </InputLabel>
                          <Select
                            id="Average-Booking-Value-id"
                            labelId="Average-Booking-Value-label"
                            label="ABV"
                            onChange={(event) => {
                              setSelectedAvgValue(event.target.value);
                              if (event.target.value === "default") {
                                setavgBookingValue(Math.round(4200));
                              } else if (event.target.value === "abv") {
                                setavgBookingValue(
                                  Math.round(userInfo?.abv_lifetime)
                                );
                              }
                            }}
                            fullWidth
                            defaultValue={"abv"}
                            value={selectedAvgValue}
                          >
                            <MenuItem value="abv">Individual ABV</MenuItem>
                            <MenuItem value="default">Default</MenuItem>
                            <MenuItem value="custom">Custom</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                      <Grid item xs={8}>
                        <Item>
                          <Slider
                            min={0}
                            max={25000}
                            value={avgBookingValue || 0}
                            onChange={(event, newValue) =>
                              setavgBookingValue(newValue)
                            }
                            aria-labelledby="input-slider1"
                            step={1}
                            marks={[
                              {
                                value: 0,
                                label: `£ 0`,
                              },
                              {
                                value: 25000,
                                label: `£ ${formatter.format(25000)}`,
                              },
                            ]}
                            valueLabelDisplay="auto"
                            getAriaValueText={(value) => {
                              return `£  ${formatter.format(value)}`;
                            }}
                            valueLabelFormat={(value) => {
                              return `£  ${formatter.format(value)}`;
                            }}
                          />
                        </Item>
                      </Grid>
                      <Grid item xs={4}>
                        <Item>
                          <TextField
                            fullWidth
                            type="text"
                            value={avgBookingValue || 0}
                            autoComplete="avgBookingValue"
                            label="ABV"
                            onChange={(event) => {
                              if (
                                selectedAvgValue !== "custom" &&
                                Math.round(userInfo?.abv_lifetime) !==
                                  Math.round(Number(event.target.value)) &&
                                Math.round(4200) !==
                                  Math.round(Number(event.target.value))
                              ) {
                                setSelectedAvgValue("custom");
                              }
                              setavgBookingValue(
                                event.target.value === ""
                                  ? 0
                                  : Math.round(Number(event.target.value))
                              );
                            }}
                            InputProps={{
                              inputComponent: NumericFormatCustom,
                            }}
                            inputProps={{
                              inputMode: "numeric",
                              pattern: "[0-9]*",
                              prefix: "£ ",
                            }}
                          />
                        </Item>
                      </Grid>
                    </Grid>
                  </Stack>
                </Item>
              )}
              <Divider />
              {isMobile ? (
                <Item>
                  <Stack direction={{ xs: "column", sm: "row" }}>
                    <Item>
                      <Stack direction="row">
                        <Item>
                          <FormControl sx={{ width: 120 }}>
                            <InputLabel id="avg-booking-margin-label">
                              Margin
                            </InputLabel>
                            <Select
                              id="avg-booking-margin-id"
                              labelId="avg-booking-margin-label"
                              label="Margin"
                              onChange={(event) => {
                                setSelectedMargin(event.target.value);
                                if (event.target.value === "abm") {
                                  setavgBookingMargin(
                                    Math.round(userInfo?.lifetime_margin * 10) /
                                      10
                                  );
                                }
                              }}
                              fullWidth
                              defaultValue={"abm"}
                              value={selectedMargin}
                            >
                              <MenuItem value="abm">Individual Margin</MenuItem>
                              <MenuItem value="custom">Custom</MenuItem>
                            </Select>
                          </FormControl>
                        </Item>

                        <Item>
                          <TextField
                            fullWidth
                            type="text"
                            value={avgBookingMargin || 0}
                            autoComplete="avgBookingMargin"
                            label="Margin"
                            onChange={(event) => {
                              if (
                                selectedMargin !== "custom" &&
                                Math.round(
                                  Number(event.target.value).toFixed(1) * 10
                                ) /
                                  10 !==
                                  Math.round(
                                    Number(userInfo?.lifetime_margin).toFixed(
                                      1
                                    ) * 10
                                  ) /
                                    10
                              ) {
                                setSelectedMargin("custom");
                              }
                              setavgBookingMargin(
                                event.target.value === ""
                                  ? 0
                                  : Math.round(
                                      Number(event.target.value).toFixed(1) * 10
                                    ) / 10
                              );
                            }}
                            InputProps={{
                              inputComponent: NumericFormatCustom,
                            }}
                            inputProps={{
                              inputMode: "numeric",
                              pattern: "[0-9]*",
                              suffix: " %",
                            }}
                          />
                        </Item>
                      </Stack>
                    </Item>
                    <Item sx={{ marginRight: 2 }}>
                      <Slider
                        min={0}
                        max={100}
                        value={avgBookingMargin || 0}
                        onChange={(event, newValue) =>
                          setavgBookingMargin(newValue)
                        }
                        step={0.1}
                        aria-labelledby="input-slider"
                        marks={[
                          {
                            value: 0,
                            label: `0 %`,
                          },
                          {
                            value: 100,
                            label: `100 %`,
                          },
                        ]}
                        valueLabelDisplay="auto"
                        getAriaValueText={(value) => {
                          return `${value} %`;
                        }}
                        valueLabelFormat={(value) => {
                          return `${value} %`;
                        }}
                      />
                    </Item>
                  </Stack>
                </Item>
              ) : (
                <Item>
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    spacing={1}
                    marginTop={0}
                  >
                    <Grid item xs={8} container spacing={2}>
                      <Grid item xs={12}>
                        <FormControl sx={{ m: 1, minWidth: 120 }} fullWidth>
                          <InputLabel id="avg-booking-margin-label">
                            Margin
                          </InputLabel>
                          <Select
                            id="avg-booking-margin-id"
                            labelId="avg-booking-margin-label"
                            label="Margin"
                            onChange={(event) => {
                              setSelectedMargin(event.target.value);
                              if (event.target.value === "abm") {
                                setavgBookingMargin(
                                  Math.round(userInfo?.lifetime_margin * 10) /
                                    10
                                );
                              }
                            }}
                            fullWidth
                            defaultValue={"abm"}
                            value={selectedMargin}
                          >
                            <MenuItem value="abm">Individual Margin</MenuItem>
                            <MenuItem value="custom">Custom</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                      <Grid item xs={8}>
                        <Item>
                          <Slider
                            min={0}
                            max={100}
                            value={avgBookingMargin || 0}
                            onChange={(event, newValue) => {
                              setavgBookingMargin(
                                Math.round(newValue * 10) / 10
                              );
                            }}
                            step={0.1}
                            aria-labelledby="input-slider"
                            marks={[
                              {
                                value: 0,
                                label: `0 %`,
                              },
                              {
                                value: 100,
                                label: `100 %`,
                              },
                            ]}
                            valueLabelDisplay="auto"
                            getAriaValueText={(value) => {
                              return `${value} %`;
                            }}
                            valueLabelFormat={(value) => {
                              return `${value} %`;
                            }}
                          />
                        </Item>
                      </Grid>
                      <Grid item xs={4}>
                        <Item>
                          <TextField
                            fullWidth
                            type="text"
                            value={avgBookingMargin || 0}
                            autoComplete="avgBookingMargin"
                            label="Margin"
                            onChange={(event) => {
                              if (
                                selectedMargin !== "custom" &&
                                Math.round(
                                  Number(event.target.value).toFixed(1) * 10
                                ) /
                                  10 !==
                                  Math.round(
                                    Number(userInfo?.lifetime_margin).toFixed(
                                      1
                                    ) * 10
                                  ) /
                                    10
                              ) {
                                setSelectedMargin("custom");
                              }
                              setavgBookingMargin(
                                event.target.value === ""
                                  ? 0
                                  : Math.round(
                                      Number(event.target.value).toFixed(1) * 10
                                    ) / 10
                              );
                            }}
                            InputProps={{
                              inputComponent: NumericFormatCustom,
                            }}
                            inputProps={{
                              inputMode: "numeric",
                              pattern: "[0-9]*",
                              suffix: " %",
                            }}
                          />
                        </Item>
                      </Grid>
                    </Grid>
                  </Stack>
                </Item>
              )}

              <Divider />
              {isMobile ? (
                <Item>
                  <Stack direction={{ xs: "column", sm: "row" }}>
                    <Item>
                      <Stack direction="row">
                        <Item>
                          <Typography
                            variant="subtitle1"
                            sx={{ margin: 0, textAlign: "left", minWidth: 120 }}
                          >
                            {"Conversion Rate"}
                          </Typography>
                        </Item>
                        <Item>
                          <TextField
                            fullWidth
                            type="text"
                            value={conversionRate || 0}
                            autoComplete="conversionRate"
                            label="Conversion rate"
                            onChange={(event) => {
                              setConversionRate(
                                event.target.value === ""
                                  ? 0
                                  : Math.round(Number(event.target.value))
                              );
                            }}
                            InputProps={{
                              inputComponent: NumericFormatCustom,
                            }}
                            inputProps={{
                              inputMode: "numeric",
                              pattern: "[0-9]*",
                              suffix: " %",
                            }}
                          />
                        </Item>
                      </Stack>
                    </Item>
                    <Item sx={{ marginRight: 2 }}>
                      <Slider
                        min={0}
                        max={100}
                        value={conversionRate || 0}
                        onChange={(event, newValue) =>
                          setConversionRate(newValue)
                        }
                        step={1}
                        aria-labelledby="input-slider"
                        marks={[
                          {
                            value: 0,
                            label: `0 %`,
                          },
                          {
                            value: 100,
                            label: `100 %`,
                          },
                        ]}
                        valueLabelDisplay="auto"
                        getAriaValueText={(value) => {
                          return `${value} %`;
                        }}
                        valueLabelFormat={(value) => {
                          return `${value} %`;
                        }}
                      />
                    </Item>
                  </Stack>
                </Item>
              ) : (
                <Item>
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    spacing={1}
                    marginTop={0}
                  >
                    <Grid item xs={8} container spacing={2}>
                      <Grid item xs={12}>
                        <Typography
                          variant="subtitle1"
                          sx={{ margin: 0, textAlign: "center" }}
                        >
                          {"Conversion Rate"}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={8}>
                        <Item>
                          <Slider
                            min={0}
                            max={100}
                            value={conversionRate || 0}
                            onChange={(event, newValue) =>
                              setConversionRate(newValue)
                            }
                            step={1}
                            aria-labelledby="input-slider"
                            marks={[
                              {
                                value: 0,
                                label: `0 %`,
                              },
                              {
                                value: 100,
                                label: `100 %`,
                              },
                            ]}
                            valueLabelDisplay="auto"
                            getAriaValueText={(value) => {
                              return `${value} %`;
                            }}
                            valueLabelFormat={(value) => {
                              return `${value} %`;
                            }}
                          />
                        </Item>
                      </Grid>
                      <Grid item xs={4}>
                        <Item>
                          <TextField
                            fullWidth
                            type="text"
                            value={conversionRate || 0}
                            autoComplete="conversionRate"
                            label="Conversion rate"
                            onChange={(event) => {
                              setConversionRate(
                                event.target.value === ""
                                  ? 0
                                  : Math.round(Number(event.target.value))
                              );
                            }}
                            InputProps={{
                              inputComponent: NumericFormatCustom,
                            }}
                            inputProps={{
                              inputMode: "numeric",
                              pattern: "[0-9]*",
                              suffix: " %",
                            }}
                          />
                        </Item>
                      </Grid>
                    </Grid>
                  </Stack>
                </Item>
              )}
              <Divider />
              {isMobile ? (
                <Item>
                  <Stack direction={{ xs: "column", sm: "row" }}>
                    <Item>
                      <Stack direction="row">
                        <Item>
                          <Typography
                            variant="subtitle1"
                            sx={{ margin: 0, textAlign: "left", minWidth: 120 }}
                          >
                            {"Opportunities"}
                          </Typography>
                        </Item>
                        <Item>
                          <TextField
                            fullWidth
                            type="text"
                            value={opportunities || 0}
                            autoComplete="opportunities"
                            label="Opportunities"
                            onClick={(event) => setProfitClicked(false)}
                            onChange={(event) => {
                              setOpportunities(
                                event.target.value === ""
                                  ? 0
                                  : Math.round(Number(event.target.value))
                              );
                              !profitClicked &&
                                setTotalProfit(
                                  calculateTotalProfitWithNewOpportunities(
                                    Number(event.target.value)
                                  )
                                );
                            }}
                            InputProps={{
                              inputComponent: NumericFormatCustom,
                            }}
                            inputProps={{
                              inputMode: "numeric",
                              pattern: "[0-9]*",
                            }}
                          />
                        </Item>
                      </Stack>
                    </Item>
                    <Item sx={{ marginRight: 2 }}>
                      <Slider
                        min={0}
                        max={3000}
                        value={opportunities || 0}
                        onChange={(event, newValue) => {
                          setOpportunities(newValue);
                          setTotalProfit(
                            calculateTotalProfitWithNewOpportunities(newValue)
                          );
                        }}
                        step={1}
                        aria-labelledby="input-slider"
                        marks={[
                          {
                            value: 0,
                            label: `0`,
                          },
                          {
                            value: 3000,
                            label: `3000`,
                          },
                        ]}
                        valueLabelDisplay="auto"
                        getAriaValueText={(value) => {
                          return `${formatter.format(value)}`;
                        }}
                        valueLabelFormat={(value) => {
                          return `${formatter.format(value)}`;
                        }}
                      />
                    </Item>
                  </Stack>
                </Item>
              ) : (
                <Item>
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    spacing={1}
                    marginTop={0}
                  >
                    <Grid item xs={8} container spacing={2}>
                      <Grid item xs={12}>
                        <Typography
                          variant="subtitle1"
                          sx={{ margin: 0, textAlign: "center" }}
                        >
                          {"Opportunities"}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={8}>
                        <Item>
                          <Slider
                            min={0}
                            max={3000}
                            value={opportunities || 0}
                            onChange={(event, newValue) => {
                              setOpportunities(newValue);
                              setTotalProfit(
                                calculateTotalProfitWithNewOpportunities(
                                  newValue
                                )
                              );
                            }}
                            step={1}
                            aria-labelledby="input-slider"
                            marks={[
                              {
                                value: 0,
                                label: `0`,
                              },
                              {
                                value: 3000,
                                label: `3000`,
                              },
                            ]}
                            valueLabelDisplay="auto"
                            getAriaValueText={(value) => {
                              return `${formatter.format(value)}`;
                            }}
                            valueLabelFormat={(value) => {
                              return `${formatter.format(value)}`;
                            }}
                          />
                        </Item>
                      </Grid>
                      <Grid item xs={4}>
                        <Item>
                          <TextField
                            fullWidth
                            type="text"
                            value={opportunities || 0}
                            autoComplete="opportunities"
                            label="Opportunities"
                            onClick={(event) => setProfitClicked(false)}
                            onChange={(event) => {
                              setOpportunities(
                                event.target.value === ""
                                  ? 0
                                  : Math.round(Number(event.target.value))
                              );
                              !profitClicked &&
                                setTotalProfit(
                                  calculateTotalProfitWithNewOpportunities(
                                    Number(event.target.value)
                                  )
                                );
                            }}
                            InputProps={{
                              inputComponent: NumericFormatCustom,
                            }}
                            inputProps={{
                              inputMode: "numeric",
                              pattern: "[0-9]*",
                            }}
                          />
                        </Item>
                      </Grid>
                    </Grid>
                  </Stack>
                </Item>
              )}

              <Divider />
              {isMobile ? (
                <Item>
                  <Stack direction={{ xs: "column", sm: "row" }}>
                    <Item>
                      <Stack direction="row">
                        <Item>
                          <Typography
                            variant="subtitle1"
                            sx={{ margin: 0, textAlign: "left", minWidth: 120 }}
                          >
                            {"Profit"}
                          </Typography>
                        </Item>
                        <Item>
                          <TextField
                            fullWidth
                            type="text"
                            value={totalProfit || 0}
                            onClick={(event) => setProfitClicked(true)}
                            autoComplete="totalProfit"
                            label="Profit"
                            onChange={(event) => {
                              setTotalProfit(
                                event.target.value === ""
                                  ? 0
                                  : Math.round(Number(event.target.value))
                              );
                              setOpportunities(
                                calculateOpportunitiesWithNewTotalProfit(
                                  Number(event.target.value)
                                )
                              );
                            }}
                            InputProps={{
                              inputComponent: NumericFormatCustom,
                            }}
                            inputProps={{
                              inputMode: "numeric",
                              pattern: "[0-9]*",
                              prefix: "£ ",
                            }}
                          />
                        </Item>
                      </Stack>
                    </Item>
                    <Item sx={{ marginRight: 2 }}>
                      <Slider
                        min={0}
                        max={500000}
                        value={totalProfit || 0}
                        onChange={(event, newValue) => {
                          setTotalProfit(newValue);
                          setOpportunities(
                            calculateOpportunitiesWithNewTotalProfit(newValue)
                          );
                        }}
                        step={1}
                        aria-labelledby="input-slider"
                        marks={[
                          {
                            value: 0,
                            label: `£ 0`,
                          },
                          {
                            value: 500000,
                            label: `£ ${formatter.format(500000)}`,
                          },
                        ]}
                        valueLabelDisplay="auto"
                        getAriaValueText={(value) => {
                          return `£  ${formatter.format(value)}`;
                        }}
                        valueLabelFormat={(value) => {
                          return `£  ${formatter.format(value)}`;
                        }}
                      />
                    </Item>
                  </Stack>
                </Item>
              ) : (
                <Item>
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    spacing={1}
                    marginTop={0}
                  >
                    <Grid item xs={8} container spacing={2}>
                      <Grid item xs={12}>
                        <Typography
                          variant="subtitle1"
                          sx={{ margin: 0, textAlign: "center" }}
                        >
                          {"Profit"}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={8}>
                        <Item>
                          <Slider
                            min={0}
                            max={500000}
                            value={totalProfit || 0}
                            onChange={(event, newValue) => {
                              setTotalProfit(newValue);
                              setOpportunities(
                                calculateOpportunitiesWithNewTotalProfit(
                                  newValue
                                )
                              );
                            }}
                            step={1}
                            aria-labelledby="input-slider"
                            marks={[
                              {
                                value: 0,
                                label: `£ 0`,
                              },
                              {
                                value: 500000,
                                label: `£ ${formatter.format(500000)}`,
                              },
                            ]}
                            valueLabelDisplay="auto"
                            getAriaValueText={(value) => {
                              return `£  ${formatter.format(value)}`;
                            }}
                            valueLabelFormat={(value) => {
                              return `£  ${formatter.format(value)}`;
                            }}
                          />
                        </Item>
                      </Grid>
                      <Grid item xs={4}>
                        <Item>
                          <TextField
                            type="text"
                            value={totalProfit || 0}
                            onClick={(event) => setProfitClicked(true)}
                            autoComplete="totalProfit"
                            label="Profit"
                            onChange={(event) => {
                              setTotalProfit(
                                event.target.value === ""
                                  ? 0
                                  : Math.round(Number(event.target.value))
                              );
                              setOpportunities(
                                calculateOpportunitiesWithNewTotalProfit(
                                  Number(event.target.value)
                                )
                              );
                            }}
                            InputProps={{
                              inputComponent: NumericFormatCustom,
                            }}
                            inputProps={{
                              inputMode: "numeric",
                              pattern: "[0-9]*",
                              prefix: "£ ",
                            }}
                          />
                        </Item>
                      </Grid>
                    </Grid>
                  </Stack>
                </Item>
              )}
            </Stack>
          </Box>
        </Card>
      </Grid>
      <Grid container>
        <Card sx={{ marginTop: 2, display: "flex", width: "100%" }}>
          <Box
            component={motion.div}
            initial={{ opacity: 0, y: 20 }}
            animate={animate}
            sx={{ margin: 0, width: `100%` }}
          >
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 450 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell
                      sx={{
                        position: "sticky",
                        left: 0,
                        backgroundColor: "black",
                      }}
                    >
                      {" "}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {isMobile ? "1D" : "1 Day"}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {isMobile ? "1W" : "1 Week"}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {isMobile ? "1M" : "1 Month"}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {isMobile ? "1Y " : "1 Year"}
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <StyledTableRow key={row.name}>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        sx={{
                          position: "sticky",
                          left: 0,
                          backgroundColor: isMobile ? "gainsboro" : "revert",
                        }}
                      >
                        {row.name}
                      </StyledTableCell>
                      <StyledTableCell align="right">{row.day}</StyledTableCell>
                      <StyledTableCell align="right">
                        {row.week}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {row.month}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {row.year}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Card>
      </Grid>
    </Container>
  );
};

export default TargetCalculator;
