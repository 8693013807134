import { Helmet } from 'react-helmet-async';
import React, { useState, useEffect } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { //Link,
  Container, Typography,
  Divider, Stack, Button, CircularProgress, Backdrop, AlertTitle, Alert, Snackbar
} from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Logo from '../components/logo';
import Iconify from '../components/iconify';
// sections
import { LoginForm } from '../sections/auth/login';
import { GoogleOAuthProvider, useGoogleLogin, GoogleLogin } from '@react-oauth/google';

import GoogleButton from 'react-google-button'
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { validateSession } from '../actions/api_calls'
// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 1000,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  // boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  const mdUp = useResponsive('up', 'md');
  const navigate = useNavigate();
  const [authToken, setAuthToken] = useState();
  const [userDetails, setUserDetails] = useState();
  const [isLoading, setLoading] = useState(false);
  const [displayError, setDisplayError] = useState(false);

  useEffect(() => {
    if (authToken !== "" && authToken !== undefined) {
      validateSession(authToken, setUserDetails);
    }
  }, [authToken])

  useEffect(() => {
    if (userDetails !== "" && userDetails !== undefined) {
      if (!userDetails.error) {
        localStorage.setItem('isAuth', true);
        localStorage.setItem('authToken', authToken);
        localStorage.setItem('userDetails', JSON.stringify(userDetails));
        navigate('/user/home/', { replace: true });
      } else if (userDetails?.error) {
        setDisplayError(true);

      }


      setLoading(false);
    }
  }, [userDetails])


  return (
    <>
      <Helmet>
        <title> Login | NJT </title>
      </Helmet>

      <StyledRoot>
        <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        />

        {mdUp && (
          <StyledSection fullWidth>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hi, Welcome Back
            </Typography>
            <img src="/assets/illustrations/illustration_dashboard.png" alt="login" />
          </StyledSection>
        )}

        <Container maxWidth="sm" >
          <StyledContent>
            <Typography variant="h4" gutterBottom>
              Sign into NJT Dashboard
            </Typography>
            <div>

            </div>

            {/* <Typography variant="body2" sx={{ mb: 5 }}>
              Don’t have an account? {''}
              <Link variant="subtitle2">Get started</Link>
            </Typography>  */}
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isLoading}

            >
              <CircularProgress color="inherit" /></Backdrop>

              <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={userDetails?.error && displayError}
        onClose={() => setDisplayError(false)}
        message={userDetails?.errorMessage}
        autoHideDuration={3000}
      />

            <Stack spacing={2} >

              <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_API_CLIENTID}>



                {/* <Button fullWidth size="large" color="inherit" variant="outlined" >
          <Iconify icon="eva:google-fill" color="#DF3E30" width={22} height={22} />
          </Button> */}
                <GoogleLogin
                  fullWiwidth
                  onSuccess={credentialResponse => {
                    setLoading(true);
                    setAuthToken(credentialResponse?.credential);

                  }}
                  onError={() => {
                    console.log('Login Failed');
                  }}
                  text=' njt login with sso'
                  render={renderProps => (
                    <GoogleButton onClick={renderProps.onClick} disabled={renderProps.disabled}>Sign in with Google</GoogleButton>
                  )}
                > </GoogleLogin>

              </GoogleOAuthProvider>
              {userDetails?.error && (<Alert variant="outlined" severity="error">
                {userDetails?.errorMessage}
              </Alert>)}

              {/* <Button fullWidth size="large" color="inherit" variant="outlined">
                <Iconify icon="eva:facebook-fill" color="#1877F2" width={22} height={22} />
              </Button> */}
              {/* 
              <Button fullWidth size="large" color="inherit" variant="outlined">
                <Iconify icon="eva:twitter-fill" color="#1C9CEA" width={22} height={22} />
              </Button>*/}
            </Stack>

            {/* <Divider sx={{ my: 3 }}>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                OR
              </Typography>
            </Divider> 

            <LoginForm/> */}

          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
