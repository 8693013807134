import PropTypes from 'prop-types';
import ArrowDownIcon from '@heroicons/react/24/solid/ArrowDownIcon';
import ArrowUpIcon from '@heroicons/react/24/solid/ArrowUpIcon';
import CurrencyPoundIcon from '@heroicons/react/24/solid/CurrencyPoundIcon';
import BookOpenIcon from '@heroicons/react/24/solid/BookOpenIcon';
import MarginIcon from '@mui/icons-material/Margin';
import DiscountIcon from '@mui/icons-material/Discount';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import PercentIcon from '@mui/icons-material/Percent';
import { Avatar, Card, CardContent, Stack, SvgIcon, Typography } from '@mui/material';

export const OverviewBudget = (props) => {
  const { difference, positive = false, sx, value , label ,sinceLabel, icon,isBooking, target} = props;

  return (
    <Card sx={sx} > 
      <CardContent>
        <Stack
          alignItems="flex-start"
          direction="row"
          justifyContent="space-between"
          spacing={3}
        >
          <Stack spacing={1}>
            <Typography
              color="text.secondary"
              variant="overline"
            >
              {label}
            </Typography>
            <Typography variant="h4">
              {value}
            </Typography>
          </Stack>
          <Avatar
            sx={{
              backgroundColor: icon==='sales' ? 'error.main': icon==='booking' ? 'darkmagenta':icon==='avgBookingValue' ? 'grey':icon==='discount' ? 'warning.main':icon==='Margin' ? 'success.main':'error.main',
              height: 56,
              width: 56
            }}
          >
            <SvgIcon>
              {icon==='sales' ? (<TrendingUpIcon/>): icon==='booking' ? (<BookOpenIcon/>):icon==='avgBookingValue' ? (<CurrencyPoundIcon/>):icon==='discount' ? (<DiscountIcon/>):icon==='Margin' ? (<MarginIcon/>):(<PercentIcon />)}
              
            </SvgIcon>
          </Avatar>
        </Stack>
        {/* {difference && (
          <Stack
            alignItems="center"
            direction="row"
            spacing={2}
            sx={{ mt: 2 }}
          >
            <Stack
              alignItems="center"
              direction="row"
              spacing={0.5}
            >
              <SvgIcon
                color={positive ? 'success' : 'error'}
                fontSize="small"
              >
                {positive ? <ArrowUpIcon /> : <ArrowDownIcon />}
              </SvgIcon>
              <Typography
                color={positive ? 'success.main' : 'error.main'}
                variant="body2"
              >
                {difference}%
              </Typography>
            </Stack>
            <Typography
              color="text.secondary"
              variant="caption"
            > {positive ? "above the Target": "below the Target "}
                {sinceLabel}
            </Typography>
          </Stack>
        )} */}
      </CardContent>
    </Card>
  );
};

OverviewBudget.prototypes = {
  difference: PropTypes.number,
  positive: PropTypes.bool,
  sx: PropTypes.object,
  value: PropTypes.string.isRequired
};
