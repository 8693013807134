import { Helmet } from 'react-helmet-async';


import React, { useState, useEffect } from "react";
import {
  Card,  Grid,
  TableRow,
  TableBody,
  TableContainer,
  
  Table,
  Drawer, Fab, Snackbar, TableHead, Link, Breadcrumbs,
  FormControlLabel ,Switch ,
  Container,
  Typography,
  Box, Stack,
  ToggleButton,
  ToggleButtonGroup,
  Paper, CircularProgress, Backdrop, Skeleton
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';


import {
  AppWebsiteVisits
} from '../sections/@dashboard/app';
import { OverviewBudget } from '../sections/overview/overview-budget';
import { OverviewTotalProfit } from '../sections/overview/overview-total-profit';
// ----------------------------------------------------------------------
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {  DatePicker } from '@mui/x-date-pickers';
import { motion } from "framer-motion";
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import { format } from 'date-fns';
import dayjs from 'dayjs';
import useResponsive from '../hooks/useResponsive';
import StyleIcon from '@mui/icons-material/Style';
import { getOverAllSummaryForUserMonthly, getOverAllSummaryForUser } from '../actions/api_calls';
import { useNavigate } from "react-router-dom";

export default function DashboardTC() {
  const getMonthYearByMonthBefore = (monthsBefore) => {
    return format(new Date(
      new Date().getFullYear(),
      new Date().getMonth() - monthsBefore,
      new Date().getDate()
    ), 'MMyyyy');
  };

  const getMonthYearForCurrentYearJan = () => {

    return format(new Date(new Date().getFullYear(), 0, 1), 'MMyyyy');

  };
  //xs, extra-small: 0px
  // sm, small: 600px
  // md, medium: 900px
  // lg, large: 1200px
  // xl, extra-large: 1536px
  const isMobile = useResponsive('down', 'sm');

 
  const navigate = useNavigate();



  const [showTilesInDrawer, setShowTilesInDrawer] = useState(false);
  const [isPageLoading, setPageLoading] = useState(true);
  const [userInfo, setUserInfo] = useState();
  const [displayError, setDisplayError] = useState(false);
  const [displayMonthlyError, setMonthlyDisplayError] = useState(false);
  const [showFromMonthPicker, setShowFromMonth] = useState(false);
  const [showToMonthPicker, setShowToMonth] = useState(false);
  const [selectedOverviewToggle, setOverviewToggleSelect] = useState("1Y");
  const [sinceLabel, setSinceLabel] = useState(" in last 1 Year")
  const [overviewStartDate, setOverviewStartDate] = useState(`${getMonthYearByMonthBefore(11)}`);
  const [overviewEndDate, setOverviewEndDate] = useState(`${getMonthYearByMonthBefore(0)}`);
  const [overviewSummaryMonthy, setOverviewSummaryMonthly] = useState();
  const [showOverviewOf, setOverviewOf] = useState("Sales");
  const [listview, setListView] = useState(true);
  const displayInShort = false;
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const [dashboardFrom, setDashboardFrom] = useState(dayjs(new Date(
    new Date().getFullYear(),
    new Date().getMonth() - 1,
    new Date().getDate()
  )));

  const [minDate, setMinDate] = useState(dayjs(new Date(
    2010,
    new Date().getMonth() - 1,
    new Date().getDate()
  )));
  const [dashboardTo, setDashboardTo] = useState(dayjs(new Date()));
  const [validationError, setValidationError] = useState();

  let formatter = Intl.NumberFormat('en-GB');
  let formatterMobile = Intl.NumberFormat('en-GB', { notation: 'compact' });

  const isDesktop = useResponsive('up', 'lg');

  function formatCellValueforMobile(value) {
    return isMobile ? formatterMobile.format(Math.round(value)) : isDesktop ? new Intl.NumberFormat('en-GB').format(Math.round(value)) : formatterMobile.format(Math.round(value));
  }

  const handleOverviewToggleSelect = (event, newTime) => {
    if (newTime !== null) {
      let selectedToggle = newTime.replace(selectedOverviewToggle, "");
      setOverviewToggleSelect(selectedToggle);
      if (selectedToggle !== "Custom") {
        let startDate = selectedToggle === "1M" ? getMonthYearByMonthBefore(0) : selectedToggle === "3M" ? getMonthYearByMonthBefore(2) : selectedToggle === "6M" ? getMonthYearByMonthBefore(5) : selectedToggle === "1Y" ? getMonthYearByMonthBefore(11) : selectedToggle === "YTD" ? getMonthYearForCurrentYearJan() : getMonthYearByMonthBefore(0);
        let endDate = getMonthYearByMonthBefore(0);

        setOverviewStartDate(`${startDate}`);
        setOverviewEndDate(`${endDate}`);

        setSinceLabel(` in last ${selectedToggle === "1M" ? "1 Month" : selectedToggle === "3M" ? "3 Months" : selectedToggle === "6M" ? "6 Months" : selectedToggle === "1Y" ? "1 Year" : selectedToggle === "YTD" ? `from ${format(new Date(new Date().getFullYear(), 0, 1), 'MMMM,yyyy')}` : ""}`)



        getOverview(startDate, endDate);

      }
    }
  }

  const getOverview = (startDate, endDate) => {
    setPageLoading(true);
    getOverAllSummaryForUserMonthly(`${startDate}`, `${endDate}`, setOverviewSummaryMonthly);

  };

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));



  const getOverviewForSelectedrange = () => {

    let currentDate = dayjs(new Date());
    let hasValidDates = true;
    if (currentDate < dashboardFrom || dashboardFrom.format("MMYYYY") === "Invalid Date") {
      console.log("invalid start Date")
      hasValidDates = false;
      setValidationError({ ...validationError, errorWithFromDate: true })
    }
    if (currentDate < dashboardTo || dashboardTo < dashboardFrom || dashboardTo.format("MMYYYY") === "Invalid Date") {
      console.log("invalid End Date")
      hasValidDates = false;
      setValidationError({ ...validationError, errorWithFromTo: true })
    }
    if (hasValidDates) {
      setSinceLabel(` from ${dashboardFrom.format("MMMM,YYYY")}`)
      getOverview(dashboardFrom.format("MMYYYY"), dashboardTo.format("MMYYYY"));
    }

  }

  useEffect(() => {

    if (userInfo !== "" && userInfo !== undefined) {

      if (userInfo?.error && userInfo?.errorCode === 401) {
        localStorage.removeItem('isAuth');
        localStorage.removeItem('userDetails');
        localStorage.removeItem('authToken');
        navigate('/');
      }
      else if (userInfo?.error && userInfo?.errorCode !== 401) {
        setDisplayError(true);
      }
      //setPageLoading(false);
    }
  }, [userInfo, navigate]);

  useEffect(() => {

    if (overviewSummaryMonthy !== "" && overviewSummaryMonthy !== undefined) {

      if (overviewSummaryMonthy?.error && overviewSummaryMonthy?.errorCode === 401) {
        localStorage.removeItem('isAuth');
        localStorage.removeItem('userDetails');
        localStorage.removeItem('authToken');
        navigate('/');

      }
      else if (overviewSummaryMonthy?.error && overviewSummaryMonthy?.errorCode !== 401) {
        setMonthlyDisplayError(true);

      }
     


      setPageLoading(false);
    }
  }, [overviewSummaryMonthy, navigate]);


  useEffect(() => {

    getOverview(overviewStartDate, overviewEndDate);
    getOverAllSummaryForUser(setUserInfo);

  }, []);

  // const Item = styled(Paper)(({ theme }) => ({
  //   // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  //   ...theme.typography.body2,
  //   padding: theme.spacing(1),
  //   textAlign: 'left',
  //   //color: 'inherit',
  //   flexGrow: 1,
  // }));

  const prefixAndSuffix = () => {

    if (showOverviewOf === "Sales") {
      return { prefix: "", suffix: "" };
    }
    if (showOverviewOf === "Margin") {
      return { prefix: "", suffix: " % " };
    }
    if (showOverviewOf === "Avg. Disc.") {
      return { prefix: " £ ", suffix: "" };
    }
    if (showOverviewOf === "ABV") {
      return { prefix: " £ ", suffix: "" };
    }
    if (showOverviewOf === "Bookings") {
      return { prefix: "", suffix: "" };
    }
    if (showOverviewOf === "Profit") {
      return { prefix: " £ ", suffix: "" };
    }
  }

  function createData(name, value) {

    return {
      name,
      booking: `${formatCellValueforMobile((value?.booking || 0))}`,
      sales: `${!isMobile ? '£ ':''}${formatCellValueforMobile((value?.sales || 0))}`,
      profit: `${!isMobile ? '£ ':''}${formatCellValueforMobile((value?.profit || 0))}`,
      discount: `${!isMobile ? '£ ':''}${formatCellValueforMobile((value?.discount || 0))}`,
      abv: `${!isMobile ? '£ ':''}${formatCellValueforMobile((value?.abv || 0))}`,
      avgMargin: `${(Math.round((value?.avgMargin || 0)*10)/10).toFixed(1)}${!isMobile ? ' %':''}`,
    };
  }


  const rows = [
    createData('TM', { booking: userInfo?.bookings_tm, sales: userInfo?.sales_tm, profit: userInfo?.profit_tm, discount: userInfo?.avg_discount_tm, abv: userInfo?.abv_tm, avgMargin: userInfo?.margin_tm }),
    createData('LM', { booking: userInfo?.bookings_lm, sales: userInfo?.sales_lm, profit: userInfo?.profit_lm, discount: userInfo?.avg_discount_lm, abv: userInfo?.abv_lm, avgMargin: userInfo?.margin_lm }),
    createData('YTD', { booking: userInfo?.bookings_ytd, sales: userInfo?.sales_ytd, profit: userInfo?.profit_ytd, discount: userInfo?.avg_discount_ytd, abv: userInfo?.abv_ytd, avgMargin: userInfo?.margin_ytd }),
    createData('Life', { booking: userInfo?.lifetime_bookings, sales: userInfo?.lifetime_sales, profit: userInfo?.lifetime_profit, discount: userInfo?.lifetime_avg_discount, abv: userInfo?.abv_lifetime, avgMargin: userInfo?.lifetime_margin })
  ];

 

  const getOverviewDataForGraph = () => {

    if (showOverviewOf === "Sales") {
      return overviewSummaryMonthy?.monthly_aggregates?.map((o) => { return (Math.round(o.sales) || 0) })
    }
    if (showOverviewOf === "Margin") {
      return overviewSummaryMonthy?.monthly_aggregates?.map((o) => { return (Math.round(o.margin*10)/10 || 0).toFixed(1) })
    }
    if (showOverviewOf === "Avg. Disc.") {
      return overviewSummaryMonthy?.monthly_aggregates?.map((o) => { return (Math.round(o.avg_discount) || 0) })
    }
    if (showOverviewOf === "ABV") {
      return overviewSummaryMonthy?.monthly_aggregates?.map((o) => { return (Math.round(o.abv) || 0) })
    }
    if (showOverviewOf === "Bookings") {
      return overviewSummaryMonthy?.monthly_aggregates?.map((o) => { return (Math.round(o.bookings) || 0) })
    }
    if (showOverviewOf === "Profit") {
      return overviewSummaryMonthy?.monthly_aggregates?.map((o) => { return (Math.round(o.profit) || 0) })
    }
  }

  let easing = [0.6, -0.05, 0.01, 0.99];
  const animate = {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.6,
      ease: easing,
      delay: 0.16,
    },
  };

  return (
    <>


      <Container maxWidth="xl">

        <Helmet>
          <title> Dashboard | NJT </title>
        </Helmet>

        <Breadcrumbs aria-label="breadcrumb">

          <Link
            underline="hover"
            color="inherit"
            href="/user/home"
          >
            Home
          </Link>

          <Typography color="text.primary">Dashboard</Typography>
        </Breadcrumbs>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Dashboard
        </Typography>



        <Box
          sx={{
            display: "inherit",
            //flexDirection: "column",
            marginLeft: 3,
            spacing: 3,
            gap: 3,
          }}
          component={motion.div}
          initial={{ opacity: 0, y: 40 }}
          animate={animate}
        >
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={userInfo?.error && displayError}
            onClose={() => setDisplayError(false)}
            message={userInfo?.errorMessage}
            autoHideDuration={3000}
          />
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={overviewSummaryMonthy?.error && displayMonthlyError}
            onClose={() => setMonthlyDisplayError(false)}
            message={overviewSummaryMonthy?.errorMessage}
            autoHideDuration={3000}
          />


          <Grid container spacing={3} sx={{ marginTop: 0, display: 'flex' }}>
            <Stack direction="row" >

              <ToggleButtonGroup
                value={selectedOverviewToggle}
                onChange={handleOverviewToggleSelect}
                aria-label="time"
                exclusive
                sx={{ display: 'flex' }}

              >
                {/* <ToggleButton value="1M" aria-label="1M">{isMobile ? '1M' : '1 Month'}</ToggleButton> */}
                <ToggleButton value="3M" aria-label="3M">{isMobile ? '3M' : '3 Months'}</ToggleButton>
                <ToggleButton value="6M" aria-label="6M">{isMobile ? '6M' : '6 Months'}</ToggleButton>
                <ToggleButton value="1Y" aria-label="1Y">{isMobile ? '1Y' : '1 Year'}</ToggleButton>
                <ToggleButton value="YTD" aria-label="YTD">{isMobile ? 'YTD' : 'Year To Date'}</ToggleButton>
                <ToggleButton value="Custom" aria-label="Custom">Custom</ToggleButton>
              </ToggleButtonGroup>
              {isMobile && (<Box sx={{
                marginTop: 0

              }} ><Fab color="secondary" aria-label="filter" onClick={() => { setShowTilesInDrawer(true) }} sx={{
                position: "fixed",
                //top: (theme) => theme.spacing(10),
                right: (theme) => theme.spacing(3)
              }}>
                  <StyleIcon />
                </Fab>
              </Box>)}
              

            </Stack>
            

          </Grid>
         
          {selectedOverviewToggle === "Custom" && (<Grid container spacing={3} sx={{ marginTop: 2, display: 'flex' }}>
            <Box
              sx={{
                display: "flex",
                //flexDirection: "column",
                spacing: 3,
                gap: 3,
              }}
              component={motion.div}
              initial={{ opacity: 0, y: 40 }}
              animate={animate}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs} >
                <DatePicker label={"From "} views={['year', 'month']}
                  onClose={() => setShowFromMonth(false)}
                  
                  defaultValue={dashboardFrom}
                  open={showFromMonthPicker}
                  maxWidth
                  minDate={minDate}
              maxDate={dayjs(new Date())}
                  onChange={(e) => {
                    setDashboardFrom(e);
                  }}
                  slotProps={{
                    textField: {
                      onClick: () => setShowFromMonth(true),
                      error: validationError?.errorWithFromDate,
                    },

                  }} />
                <DatePicker label={"To"} views={['year', 'month']}
                  onClose={() => setShowToMonth(false)}
                  minDate={dashboardFrom || minDate}
                  maxDate={dayjs(new Date())}
                  defaultValue={dashboardTo}
                  open={showToMonthPicker}
                  onChange={(e) => {
                    setDashboardTo(e);
                  }}
                  slotProps={{
                    textField: {
                      onClick: () => setShowToMonth(true),
                      error: validationError?.errorWithToDate,
                    },

                  }} />
              </LocalizationProvider>
              {/* {validationError?.errorwithTargetMonthSelect && (<FormHelperText error>Please select valid date</FormHelperText>)} */}
              <Button variant="contained" onClick={getOverviewForSelectedrange} ><SendIcon />

              </Button>
            </Box>

          </Grid>
          )}

        </Box>

        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isPageLoading}

        ><CircularProgress color="inherit" /></Backdrop>

        {isMobile ? (<div>

          <Drawer anchor='right' onClose={() => setShowTilesInDrawer(false)} open={showTilesInDrawer} ModalProps={{
            keepMounted: true,
          }}
            PaperProps={{
              sx: { width: 280 },
            }}>
            <Box
              sx={{
                display: "inherit",
                //flexDirection: "column",
                margin: 3,
                spacing: 3,
                gap: 3,
              }}
              component={motion.div}
              initial={{ opacity: 0, y: 40 }}
              animate={animate}
            >


              <Grid container spacing={3} sx={{ marginTop: 0, display: 'flex' }}>


                <Grid item xs={12} sm={6} md={3} onClick={() => { setOverviewOf("Sales"); setShowTilesInDrawer(false); }} >
                  <OverviewBudget
                    // difference={overviewSummary?.actual?.total_sales && calcPercentage(overviewSummary?.actual?.total_sales, overviewSummary?.targets?.total_sales)}
                    // positive={overviewSummary?.actual?.total_sales - overviewSummary?.targets?.total_sales > 0}
                    sx={{ height: '100%', backgroundColor: showOverviewOf === "Sales" ? 'action.selected' : "" }}
                    value={` ${formatter.format(Math.round(overviewSummaryMonthy?.summary?.sales || 0))}`}
                    //target={overviewSummary?.targets?.total_sales ? formatter.format(overviewSummary?.targets?.total_sales) : 0}
                    label="Sales"
                    sinceLabel={sinceLabel}
                    icon='sales'
                  />
                </Grid>



                <Grid item xs={12} sm={6} md={3} onClick={() => { setOverviewOf("Profit"); setShowTilesInDrawer(false); }}>
                  <OverviewTotalProfit
                    sx={{ height: '100%', backgroundColor: showOverviewOf === "Profit" ? 'action.selected' : "" }}
                    value={`£ ${formatter.format(Math.round(overviewSummaryMonthy?.summary?.profit || 0))}`}
                    //target={`£ ${overviewSummary?.targets?.total_profit ? formatter.format(overviewSummary?.targets?.total_profit) : 0}`}
                    sinceLabel={sinceLabel}
                  // difference={overviewSummary?.actual?.total_profit && calcPercentage(overviewSummary?.actual?.total_profit, overviewSummary?.targets?.total_profit)}
                  // positive={overviewSummary?.actual?.total_profit - overviewSummary?.targets?.total_profit > 0}

                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3} onClick={() => { setOverviewOf("Bookings"); setShowTilesInDrawer(false); }}>

                  <OverviewBudget
                    // difference={overviewSummary?.actual?.total_bookings && calcPercentage(overviewSummary?.actual?.total_bookings, overviewSummary?.targets?.total_bookings)}
                    // positive={overviewSummary?.actual?.total_bookings - overviewSummary?.targets?.total_bookings > 0}
                    sx={{ height: '100%', backgroundColor: showOverviewOf === "Bookings" ? 'action.selected' : "" }}
                    value={`${formatter.format(Math.round(overviewSummaryMonthy?.summary?.bookings || 0))}`}
                    // target={overviewSummary?.targets?.total_bookings ? formatter.format(overviewSummary?.targets?.total_bookings) : 0}
                    label="Bookings"
                    sinceLabel={sinceLabel}
                    icon='booking'
                  />

                </Grid>


                <Grid item xs={12} sm={6} md={3} onClick={() => { setOverviewOf("ABV"); setShowTilesInDrawer(false); }}>
                  <OverviewBudget
                    // difference={overviewSummary?.actual?.total_bookings && calcPercentage(overviewSummary?.actual?.total_bookings, overviewSummary?.targets?.total_bookings)}
                    // positive={overviewSummary?.actual?.total_bookings - overviewSummary?.targets?.total_bookings > 0}
                    sx={{ height: '100%', backgroundColor: showOverviewOf === "ABV" ? 'action.selected' : "" }}
                    value={`£ ${formatter.format(Math.round(overviewSummaryMonthy?.summary?.abv || 0))}`}
                    // target={overviewSummary?.targets?.total_bookings ? formatter.format(overviewSummary?.targets?.total_bookings) : 0}
                    label="ABV"
                    sinceLabel={sinceLabel}
                    icon='avgBookingValue'
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} onClick={() => { setOverviewOf("Avg. Disc."); setShowTilesInDrawer(false); }}>
                  <OverviewBudget
                    // difference={overviewSummary?.actual?.total_bookings && calcPercentage(overviewSummary?.actual?.total_bookings, overviewSummary?.targets?.total_bookings)}
                    // positive={overviewSummary?.actual?.total_bookings - overviewSummary?.targets?.total_bookings > 0}
                    sx={{ height: '100%', backgroundColor: showOverviewOf === "Avg. Disc." ? 'action.selected' : "" }}
                    value={`£ ${formatter.format(Math.round((overviewSummaryMonthy?.summary?.avg_discount || 0))/10)}`}
                    //target={overviewSummary?.targets?.total_bookings ? formatter.format(overviewSummary?.targets?.total_bookings) : 0}
                    label="Avg. Disc."
                    sinceLabel={sinceLabel}
                    icon='discount'
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3} onClick={() => { setOverviewOf("Margin"); setShowTilesInDrawer(false); }}>
                  <OverviewBudget
                    // difference={overviewSummary?.actual?.total_bookings && calcPercentage(overviewSummary?.actual?.total_bookings, overviewSummary?.targets?.total_bookings)}
                    // positive={overviewSummary?.actual?.total_bookings - overviewSummary?.targets?.total_bookings > 0}
                    sx={{ height: '100%', backgroundColor: showOverviewOf === "Margin" ? 'action.selected' : "" }}
                    value={` ${formatter.format((Math.round((overviewSummaryMonthy?.summary?.margin || 0)*10)/10).toFixed(1))} %`}
                    //target={overviewSummary?.targets?.total_bookings ? formatter.format(overviewSummary?.targets?.total_bookings) : 0}
                    label="Margin"
                    sinceLabel={sinceLabel}
                    icon='Margin'
                  />
                </Grid>

              </Grid></Box></Drawer>
        </div>) : (<Grid container spacing={3} sx={{ marginTop: 0, display: 'flex' }}>


          <Grid item xs={12} sm={6} md={3} onClick={() => { setOverviewOf("Sales"); setShowTilesInDrawer(false); }} >
            <OverviewBudget
              // difference={overviewSummary?.actual?.total_sales && calcPercentage(overviewSummary?.actual?.total_sales, overviewSummary?.targets?.total_sales)}
              // positive={overviewSummary?.actual?.total_sales - overviewSummary?.targets?.total_sales > 0}
              sx={{ height: '100%', backgroundColor: showOverviewOf === "Sales" ? 'action.selected' : "" }}
              value={` ${formatter.format(Math.round(overviewSummaryMonthy?.summary?.sales || 0))}`}
              //target={overviewSummary?.targets?.total_sales ? formatter.format(overviewSummary?.targets?.total_sales) : 0}
              label="Sales"
              sinceLabel={sinceLabel}
              icon='sales'
            />
          </Grid>



          <Grid item xs={12} sm={6} md={3} onClick={() => { setOverviewOf("Profit"); setShowTilesInDrawer(false); }}>
            <OverviewTotalProfit
              sx={{ height: '100%', backgroundColor: showOverviewOf === "Profit" ? 'action.selected' : "" }}
              value={`£ ${formatter.format(Math.round(overviewSummaryMonthy?.summary?.profit || 0))}`}
              //target={`£ ${overviewSummary?.targets?.total_profit ? formatter.format(overviewSummary?.targets?.total_profit) : 0}`}
              sinceLabel={sinceLabel}
            // difference={overviewSummary?.actual?.total_profit && calcPercentage(overviewSummary?.actual?.total_profit, overviewSummary?.targets?.total_profit)}
            // positive={overviewSummary?.actual?.total_profit - overviewSummary?.targets?.total_profit > 0}

            />
          </Grid>

          <Grid item xs={12} sm={6} md={3} onClick={() => { setOverviewOf("Bookings"); setShowTilesInDrawer(false); }}>

            <OverviewBudget
              // difference={overviewSummary?.actual?.total_bookings && calcPercentage(overviewSummary?.actual?.total_bookings, overviewSummary?.targets?.total_bookings)}
              // positive={overviewSummary?.actual?.total_bookings - overviewSummary?.targets?.total_bookings > 0}
              sx={{ height: '100%', backgroundColor: showOverviewOf === "Bookings" ? 'action.selected' : "" }}
              value={`${formatter.format(Math.round(overviewSummaryMonthy?.summary?.bookings || 0))}`}
              // target={overviewSummary?.targets?.total_bookings ? formatter.format(overviewSummary?.targets?.total_bookings) : 0}
              label="Bookings"
              sinceLabel={sinceLabel}
              icon='booking'
            />

          </Grid>


          <Grid item xs={12} sm={6} md={3} onClick={() => { setOverviewOf("ABV"); setShowTilesInDrawer(false); }}>
            <OverviewBudget
              // difference={overviewSummary?.actual?.total_bookings && calcPercentage(overviewSummary?.actual?.total_bookings, overviewSummary?.targets?.total_bookings)}
              // positive={overviewSummary?.actual?.total_bookings - overviewSummary?.targets?.total_bookings > 0}
              sx={{ height: '100%', backgroundColor: showOverviewOf === "ABV" ? 'action.selected' : "" }}
              value={`£ ${formatter.format(Math.round(overviewSummaryMonthy?.summary?.abv || 0))}`}
              // target={overviewSummary?.targets?.total_bookings ? formatter.format(overviewSummary?.targets?.total_bookings) : 0}
              label="ABV"
              sinceLabel={sinceLabel}
              icon='avgBookingValue'
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} onClick={() => { setOverviewOf("Avg. Disc."); setShowTilesInDrawer(false); }}>
            <OverviewBudget
              // difference={overviewSummary?.actual?.total_bookings && calcPercentage(overviewSummary?.actual?.total_bookings, overviewSummary?.targets?.total_bookings)}
              // positive={overviewSummary?.actual?.total_bookings - overviewSummary?.targets?.total_bookings > 0}
              sx={{ height: '100%', backgroundColor: showOverviewOf === "Avg. Disc." ? 'action.selected' : "" }}
              value={`£ ${formatter.format(Math.round(overviewSummaryMonthy?.summary?.avg_discount || 0))}`}
              //target={overviewSummary?.targets?.total_bookings ? formatter.format(overviewSummary?.targets?.total_bookings) : 0}
              label="Avg. Disc."
              sinceLabel={sinceLabel}
              icon='discount'
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3} onClick={() => { setOverviewOf("Margin"); setShowTilesInDrawer(false); }}>
            <OverviewBudget
              // difference={overviewSummary?.actual?.total_bookings && calcPercentage(overviewSummary?.actual?.total_bookings, overviewSummary?.targets?.total_bookings)}
              // positive={overviewSummary?.actual?.total_bookings - overviewSummary?.targets?.total_bookings > 0}
              sx={{ height: '100%', backgroundColor: showOverviewOf === "Margin" ? 'action.selected' : "" }}
              value={` ${formatter.format((Math.round((overviewSummaryMonthy?.summary?.margin || 0)*10)/10).toFixed(1))} %`}
              //target={overviewSummary?.targets?.total_bookings ? formatter.format(overviewSummary?.targets?.total_bookings) : 0}
              label="Margin"
              sinceLabel={sinceLabel}
              icon='Margin'
            />
          </Grid>

        </Grid>)}

        <Grid container spacing={3} sx={{ marginTop: 0, display: 'flex' }}>

          <Grid item md xs={12}  >
            <AppWebsiteVisits
              title={`Overview of ${showOverviewOf}`}
              label={showOverviewOf}
              //subheader="(+43%) than last year"
              chartLabels={overviewSummaryMonthy?.monthly_aggregates?.map(o => { return format(o.year_mon, "MMM,yy") }) || []}
              chartData={[
                {
                  name: showOverviewOf,
                  type: 'bar',
                  fill: 'pattern',
                  data: getOverviewDataForGraph() || [],
                },

              ]}
              prefixandsuffix={prefixAndSuffix()}
              isMobile={isMobile}
            />
          </Grid>


        </Grid>

        {userInfo ? (!userInfo?.error && (<Grid container  >
        {isMobile && ( <Box
              component={motion.div}
              initial={{ opacity: 0, y: 20 }}
              animate={animate}
              sx={{ marginTop: 2, width: `100%`, }}
            ><FormControlLabel
          value="start"
          control={<Switch color="primary"  checked ={!!listview} onClick={()=>{setListView(!listview)}}/>}
          label="Display Summary in List View"
          labelPlacement="start"
        /></Box>)}
          <Card sx={{ marginTop: 2, display: 'flex', width: '100%' }} >


          <Box
              component={motion.div}
              initial={{ opacity: 0, y: 20 }}
              animate={animate}
              sx={{ margin: 0, width: `100%`, }}
            >
               
          {isMobile && listview ? (<Box
              component={motion.div}
              initial={{ opacity: 0, y: 20 }}
              animate={animate}
              sx={{ margin: 0, width: `100%`, }}
            >
              <TableContainer component={Paper} style={{ overflowX: "initial", display: 'flex' }}>
                <Table sx={{
                  minWidth: 450, [`& .${tableCellClasses.root}`]: {
                    borderBottom: "none"
                  }
                }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell sx={{ position: 'sticky', left: 0, backgroundColor: 'black' }}> User's Over All Summary</StyledTableCell> 

                    </TableRow>
                  </TableHead>
                  <TableBody>

                    <StyledTableRow key={'mobile_row1'} sx={{ position: 'sticky', left: 0 }}>

                      <StyledTableCell align="center">
                       <Table  style={{ tableLayout: 'inherit' }}>
                          <TableBody align='center' >
                            <TableRow>
                              <TableCell width={130}>
                                <Typography variant='subtitle1' sx={{ textDecoration: 'underline', alignContent: 'center' }} display="inline">
                                  {'Bookings'}
                                </Typography>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                TM
                              </TableCell><TableCell width={windowDimensions?.width<=375?10:100}>:</TableCell><TableCell>
                                {`${formatCellValueforMobile(userInfo?.bookings_tm)}`}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                LM</TableCell><TableCell width={windowDimensions?.width<=375?10:100}>:</TableCell><TableCell>
                                {`${formatCellValueforMobile(userInfo?.bookings_lm)}`}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                YTD</TableCell><TableCell width={windowDimensions?.width<=375?10:100}>:</TableCell><TableCell>
                                {`${formatCellValueforMobile(userInfo?.bookings_ytd)}`}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                Life</TableCell><TableCell width={windowDimensions?.width<=375?10:100}>:</TableCell><TableCell>
                                {`${formatCellValueforMobile(userInfo?.lifetime_bookings)}`}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                          </Table>
                      </StyledTableCell>

                    </StyledTableRow>
                    <StyledTableRow key={'mobile_row2'} sx={{ position: 'sticky', left: 0 }}>

                      

                      <StyledTableCell align="left">
                       <Table  style={{ tableLayout: 'inherit' }}>
                      <TableBody align='center'  >
                            <TableRow>
                              <TableCell width={130}>
                          <Typography variant='subtitle1' sx={{ textDecoration: 'underline', alignContent: 'left' }} display="inline">
                            {'Sales'}
                          </Typography>
                          </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>TM </TableCell><TableCell width={windowDimensions?.width<=375?10:100}>:</TableCell><TableCell>{`£ ${formatCellValueforMobile(userInfo?.sales_tm)}`}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>LM </TableCell><TableCell width={windowDimensions?.width<=375?10:100}>:</TableCell><TableCell>{`£ ${formatCellValueforMobile(userInfo?.sales_tm)}`}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>YTD </TableCell><TableCell width={windowDimensions?.width<=375?10:100}>:</TableCell><TableCell>{`£ ${formatCellValueforMobile(userInfo?.sales_ytd)}`}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Life </TableCell><TableCell width={windowDimensions?.width<=375?10:100}>:</TableCell><TableCell>{`£ ${formatCellValueforMobile(userInfo?.lifetime_sales)}`}</TableCell>
                            </TableRow>
                            </TableBody>
                            </Table>
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key={'mobile_row3'} sx={{ position: 'sticky', left: 0 }}>
                      <StyledTableCell align="left">   <Table  style={{ tableLayout: 'inherit' }}>
                      <TableBody align='center' >
                            <TableRow>
                              <TableCell width={130}>
                          <Typography variant='subtitle1' sx={{ textDecoration: 'underline', alignContent: 'left' }} display="inline">
                            {'Profit'}
                          </Typography>
                          </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>TM </TableCell><TableCell width={windowDimensions?.width<=375?10:100}>:</TableCell><TableCell>{`£ ${formatCellValueforMobile(userInfo?.profit_tm)}`}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>LM </TableCell><TableCell width={windowDimensions?.width<=375?10:100}>:</TableCell><TableCell>{`£ ${formatCellValueforMobile(userInfo?.profit_lm)}`}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>YTD </TableCell><TableCell width={windowDimensions?.width<=375?10:100}>:</TableCell><TableCell>{`£ ${formatCellValueforMobile(userInfo?.profit_ytd)}`}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Life </TableCell><TableCell width={windowDimensions?.width<=375?10:100}>:</TableCell><TableCell>{`£ ${formatCellValueforMobile(userInfo?.lifetime_profit)}`}</TableCell>
                            </TableRow>
                            </TableBody></Table>
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key={'mobile_row4'} sx={{ position: 'sticky', left: 0 }}>
                      <StyledTableCell align="left">
                         <Table  style={{ tableLayout: 'inherit' }}>
                      <TableBody align='center'   >
                            <TableRow>
                              <TableCell width={130}>
                          <Typography variant='subtitle1' sx={{ textDecoration: 'underline', alignContent: 'left' }} display="inline">
                            {'ABV'}
                          </Typography>
                          </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>TM </TableCell><TableCell width={windowDimensions?.width<=375?10:100}>:</TableCell><TableCell>{`£ ${formatCellValueforMobile(userInfo?.abv_tm)}`}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>LM </TableCell><TableCell width={windowDimensions?.width<=375?10:100}>:</TableCell><TableCell>{`£ ${formatCellValueforMobile(userInfo?.abv_lm)}`}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>YTD </TableCell><TableCell width={windowDimensions?.width<=375?10:100}>:</TableCell><TableCell>{`£ ${formatCellValueforMobile(userInfo?.abv_ytd)}`}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Life </TableCell><TableCell width={windowDimensions?.width<=375?10:100}>:</TableCell><TableCell>{`£ ${formatCellValueforMobile(userInfo?.abv_lifetime)}`}</TableCell>
                            </TableRow>
                            </TableBody>
                            </Table>
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key={'mobile_row5'} sx={{ position: 'sticky', left: 0 }}>
                      <StyledTableCell align="left"> <Table  style={{ tableLayout: 'inherit' }}>
                      <TableBody align='center'   >
                            <TableRow>
                              <TableCell width={130}>
                          <Typography variant='subtitle1' sx={{ textDecoration: 'underline', alignContent: 'left' }} display="inline">
                            {'Avg. Disc.'}
                          </Typography>
                          </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>TM </TableCell><TableCell width={windowDimensions?.width<=375?10:100}>:</TableCell><TableCell>{`£ ${formatCellValueforMobile(userInfo?.avg_discount_tm)}`}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>LM</TableCell><TableCell width={windowDimensions?.width<=375?10:100}>:</TableCell><TableCell>{`£ ${formatCellValueforMobile(userInfo?.avg_discount_lm)}`}
                        </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>YTD</TableCell><TableCell width={windowDimensions?.width<=375?10:100}>:</TableCell><TableCell>{`£ ${formatCellValueforMobile(userInfo?.avg_discount_ytd)}`}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Life </TableCell><TableCell width={windowDimensions?.width<=375?10:100}>:</TableCell><TableCell>{`£ ${formatCellValueforMobile(userInfo?.lifetime_avg_discount)}`}</TableCell>
                            </TableRow>
                            </TableBody>
                            </Table>
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key={'mobile_row6'} sx={{ position: 'sticky', left: 0 }}>
                      <StyledTableCell align="left">
                         <Table  style={{ tableLayout: 'inherit' }}>
                      <TableBody align='center'   >
                            <TableRow>
                              <TableCell width={130}>
                          <Typography variant='subtitle1' sx={{ textDecoration: 'underline', alignContent: 'left' }} display="inline">
                            {'Margin'}
                          </Typography>
                          </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>


                        TM </TableCell><TableCell width={windowDimensions?.width<=375?10:100}>:</TableCell><TableCell>{`${(Math.round((userInfo?.margin_tm||0)*10)/10).toFixed(1)} %`}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>LM </TableCell><TableCell width={windowDimensions?.width<=375?10:100}>:</TableCell><TableCell>{`${(Math.round((userInfo?.margin_lm||0)*10)/10).toFixed(1)} %`}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>YTD </TableCell><TableCell width={windowDimensions?.width<=375?10:100}>:</TableCell><TableCell>{`${(Math.round((userInfo?.margin_ytd||0)*10)/10).toFixed(1)} %`}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Life </TableCell><TableCell width={windowDimensions?.width<=375?10:100}>:</TableCell><TableCell>{`${(Math.round((userInfo?.lifetime_margin||0)*10)/10).toFixed(1)} %`}</TableCell>
                            </TableRow>
                            </TableBody>
                            </Table>
                      </StyledTableCell>




                    </StyledTableRow>


                  </TableBody>
                </Table>
              </TableContainer></Box>) : (<Box
              component={motion.div}
              initial={{ opacity: 0, y: 20 }}
              animate={animate}
              sx={{ margin: 0, width: `100%`, }}
            ><TableContainer component={Paper}>
                <Table sx={{ minWidth: 450, }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell sx={{ position: 'sticky', left: 0 }}>{``}</StyledTableCell>
                      <StyledTableCell align="right">{'Bookings'}</StyledTableCell>
                      <StyledTableCell align="right">{`Sales${isMobile ? ' (£)':''}`}</StyledTableCell>
                      <StyledTableCell align="right">{`Profit${isMobile ? ' (£)':''}`}</StyledTableCell>
                      <StyledTableCell align="right">{`ABV${isMobile ? ' (£)':''}`}</StyledTableCell>
                      <StyledTableCell align="right">{`Avg. Disc.${isMobile ? ' (£)':''}`}</StyledTableCell>
                      <StyledTableCell align="right">{`Margin${isMobile ? ' (%)':''}`}</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <StyledTableRow key={row.name}>
                        <StyledTableCell component="th" scope="row" sx={{ position: 'sticky', left: 0, backgroundColor: isMobile ? 'gainsboro' : "revert" }}>
                        <Typography variant='subtitle1' >
                          {row.name}</Typography>
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.booking}</StyledTableCell>
                        <StyledTableCell align="right">{row.sales}</StyledTableCell>
                        <StyledTableCell align="right"><Typography variant='subtitle1' >
                          {row.profit}
                        </Typography></StyledTableCell>
                        <StyledTableCell align="right">{row.abv}</StyledTableCell>
                        <StyledTableCell align="right">{row.discount}</StyledTableCell>
                        <StyledTableCell align="right">{row.avgMargin}</StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer></Box>)}


              </Box>
          
          </Card>

        </Grid>)):(<Box
              component={motion.div}
              initial={{ opacity: 0, y: 20 }}
              animate={animate}
              sx={{ marginTop: 2, width: `100%`, }}
            ><Skeleton variant="rectangular" width={isMobile?345:600} height={300} /></Box>)}


      </Container>
    </>
  );
}
