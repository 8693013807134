import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import {
  Card,
  Box,
  Link,
  Stack,
  TextField,
  Container,
  Typography,
  Backdrop,
  CircularProgress,
  Snackbar,
  Grid,
  Breadcrumbs,
  Tooltip,
  Popover,
  Avatar,
  IconButton,
  InputAdornment,
  Slider,
} from "@mui/material";
import { alpha } from "@mui/material/styles";
import InfoIcon from "@mui/icons-material/Info";
import { motion } from "framer-motion";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { getOverAllSummaryForUser } from "../actions/api_calls";

import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";

const customTheme = (outerTheme) =>
  createTheme({
    //shadows: "none",
    shadows: Array(25).fill("none"),
    typography: {
      fontFamily: ["Public Sans", "sans-serif"].join(","),
    },
    palette: {
      mode: outerTheme.palette.mode,
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            "--TextField-brandBorderColor": "#E0E3E7",
            "--TextField-brandBorderHoverColor": "#B2BAC2",
            "--TextField-brandBorderFocusedColor": "#6F7E8C",
            "& label.Mui-focused": {
              color: "#3c3f42",
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: "var(--TextField-brandBorderColor)",
          },
          root: {
            [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: "var(--TextField-brandBorderHoverColor)",
            },
            [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: "var(--TextField-brandBorderFocusedColor)",
            },
          },
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            "&:before, &:after": {
              borderBottom: "2px solid var(--TextField-brandBorderColor)",
            },
            "&:hover:not(.Mui-disabled, .Mui-error):before": {
              borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
            },
            "&.Mui-focused:after": {
              borderBottom:
                "2px solid var(--TextField-brandBorderFocusedColor)",
            },
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            "&:before": {
              borderBottom: "2px solid var(--TextField-brandBorderColor)",
            },
            "&:hover:not(.Mui-disabled, .Mui-error):before": {
              borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
            },
            "&.Mui-focused:after": {
              borderBottom:
                "2px solid var(--TextField-brandBorderFocusedColor)",
            },
          },
        },
      },
    },
  });

let easing = [0.6, -0.05, 0.01, 0.99];
const animate = {
  opacity: 1,
  y: 0,
  transition: {
    duration: 0.6,
    ease: easing,
    delay: 0.16,
  },
};

const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  //color: theme.palette.text.secondary,
  flexGrow: 1,
}));

const UserHome = () => {
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));

  const capitalizeFirstLetter = (str) => {
    const arr = str.split(" ");
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }

    return arr.join(" ");
  };

  const outerTheme = useTheme();
  const navigate = useNavigate();

  const [isPageLoading, setPageLoading] = useState(true);
  const [userInfo, setUserInfo] = useState();
  const [displayError, setDisplayError] = useState(false);
  const [showForwardComsTooltip, setShowForwardComsToolTip] = useState(false);
  const [showBDMInfo, setShowBDMInfo] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    getOverAllSummaryForUser(setUserInfo);
  }, []);

  useEffect(() => {
    if (userInfo !== "" && userInfo !== undefined) {
      if (userInfo?.error && userInfo?.errorCode === 401) {
        localStorage.removeItem("isAuth");
        localStorage.removeItem("userDetails");
        localStorage.removeItem("authToken");
        navigate("/");
      } else if (userInfo?.error && userInfo?.errorCode !== 401) {
        setDisplayError(true);
      }

      setPageLoading(false);
    }
  }, [userInfo]);

  return (
    <Container maxWidth="lg">
      {/* <Typography variant="h4" gutterBottom>
        Hi , {capitalizeFirstLetter(userDetails?.name || "")}
      </Typography> */}
      <Helmet>
        <title> User Home | NJT </title>
      </Helmet>

      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/user/home">
          Home
        </Link>
        <Typography color="text.primary">User Info</Typography>
      </Breadcrumbs>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={userInfo?.error && displayError}
        onClose={() => setDisplayError(false)}
        message={userInfo?.errorMessage}
        autoHideDuration={3000}
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isPageLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Grid container spacing={3} sx={{ marginTop: 1, display: "flex" }}>
        {/* <Grid item xs={12} md={4} >
          <Card sx={{ marginTop: 0, display: 'flex', justifyContent: "center" }} spacing={{ xs: 12, sm: 6 }}>
            <Box
              component={motion.div}
              initial={{ opacity: 0, y: 20 }}
              animate={animate}

            >
              <Stack spacing={{ xs: 6, sm: 5, lg: 4 }} direction="column" useFlexGap >
                <Item sx={{ justifyContent: "center" }}>
                  <Avatar sx={{ width: 150, height: 150, margin: "auto", marginTop: 5, marginBottom: 0 }} src={userDetails?.picture} alt={capitalizeFirstLetter(userDetails?.name || "")}></Avatar>
                </Item>
                <Item>
                  <Typography variant="h4" gutterBottom>
                    {userInfo?.internal_display_full_name || capitalizeFirstLetter(userDetails?.name || "")}

                  </Typography>
                  <Typography variant="caption" display="block" gutterBottom>
                    email : {userDetails?.email}
                  </Typography>
                </Item>
              </Stack>
            </Box>
          </Card>
        </Grid> */}

        <Grid item xs={12} md={8}>
          <Card sx={{ marginTop: 0, display: "" }}>
            <Box
              component={motion.div}
              initial={{ opacity: 0, y: 20 }}
              animate={animate}
              sx={{ marginTop: 2 }}
            >
              {userInfo && !userInfo?.error && (
                <div>
                  <ThemeProvider theme={customTheme(outerTheme)}>
                    <Stack
                      sx={{ margin: 2 }}
                      direction={{ xs: "column", sm: "row" }}
                      flexWrap="wrap"
                    >
                      <Item>
                        <TextField
                          fullWidth
                          type="text"
                          autoComplete="bdm_today"
                          label="Your BDM"
                          onClick={handleClick}
                          defaultValue={userInfo?.bdm_today || ""}
                          InputProps={{
                            readOnly: true,
                            endAdornment: (
                              <InputAdornment position="start">
                                <InfoIcon
                                  sx={{
                                    p: 0,
                                    ...(open && {
                                      "&:before": {
                                        zIndex: 1,
                                        content: "''",
                                        width: "100%",
                                        height: "100%",
                                        borderRadius: "50%",
                                        position: "absolute",
                                        bgcolor: (theme) =>
                                          alpha(theme.palette.grey[900], 0.8),
                                      },
                                    }),
                                  }}
                                ></InfoIcon>
                              </InputAdornment>
                            ),
                          }}
                          //onClick={(event) =>{console.log("showBDM"); setShowBDMInfo(true);}}
                        />
                        <Backdrop
                          sx={{
                            color: "#fff",
                            zIndex: (theme) => theme.zIndex.drawer + 1,
                          }}
                          open={open}
                        >
                          <Popover
                            open={Boolean(open)}
                            anchorEl={anchorEl}
                            onClose={(event) => setAnchorEl(null)}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            PaperProps={{
                              sx: {
                                p: 0,
                                mt: 1.5,
                                ml: 0.75,
                                width: 300,
                                "& .MuiMenuItem-root": {
                                  typography: "body2",
                                  borderRadius: 0.75,
                                },
                              },
                            }}
                          >
                            {/* <Card
                            sx={{
                              marginTop: 0,
                              display: "flex",
                              justifyContent: "center",
                            }}
                            spacing={{ xs: 12, sm: 6 }}
                          > */}
                            <Box
                              component={motion.div}
                              sx={{ my: 1.5, px: 2.5, boxShadow: 3 }}
                              // initial={{ opacity: 0, y: 20 }}
                              // animate={animate}
                            >
                              <Stack
                                spacing={{ xs: 6, sm: 5, lg: 4 }}
                                direction="column"
                                useFlexGap
                              >
                                <Item sx={{ justifyContent: "center" }}>
                                  <Avatar
                                    sx={{
                                      width: 150,
                                      height: 150,
                                      margin: "auto",
                                      marginTop: 5,
                                      marginBottom: 0,
                                    }}
                                    src={"/assets/images/avatars/avatar_12.jpg"}
                                    alt={capitalizeFirstLetter(
                                      userInfo?.bdm_today || ""
                                    )}
                                  ></Avatar>
                                </Item>
                                <Item>
                                  <Typography variant="h6" gutterBottom>
                                    {capitalizeFirstLetter(
                                      userInfo?.bdm_today || ""
                                    )}
                                  </Typography>
                                  {userInfo?.bdm_contact_email && (
                                    <Typography
                                      variant="caption"
                                      display="block"
                                      gutterBottom
                                    >
                                      <b> email : </b>{" "}
                                      <Link
                                        target="_blank"
                                        href={`mailto:${userInfo?.bdm_contact_email}`}
                                      >
                                        {userInfo?.bdm_contact_email}
                                      </Link>
                                    </Typography>
                                  )}
                                  {userInfo?.bdm_contact_phone && (
                                    <Typography
                                      variant="caption"
                                      display="block"
                                      gutterBottom
                                    >
                                      <b> Phone : </b>{" "}
                                      <Link
                                        target="_blank"
                                        href={`tel:${userInfo?.bdm_contact_phone}`}
                                      >
                                        {userInfo?.bdm_contact_phone}
                                      </Link>
                                    </Typography>
                                  )}
                                  {userInfo?.bdm_book_meeting && (
                                    <Typography
                                      variant="caption"
                                      display="block"
                                      gutterBottom
                                    >
                                      <b> Book a Call : </b>
                                      <Link
                                        target="_blank"
                                        href={userInfo?.bdm_book_meeting}
                                      >
                                        Click Here
                                      </Link>
                                    </Typography>
                                  )}
                                </Item>
                              </Stack>
                            </Box>
                            {/* </Card> */}
                          </Popover>
                        </Backdrop>
                      </Item>
                      <Item>
                        <Tooltip
                          title="Value indicates the total forward comms for the depatures from current month onwards"
                          arrow
                          open={showForwardComsTooltip}
                        >
                          <TextField
                            fullWidth
                            type="text"
                            autoComplete="Forward_Comms"
                            label="Forward Comms"
                            onBlur={(event) => setShowForwardComsToolTip(false)}
                            onClick={(event) => {
                              setShowForwardComsToolTip(true);
                              setTimeout(
                                () => setShowForwardComsToolTip(false),
                                2000
                              );
                            }}
                            defaultValue={
                              ` £ ${new Intl.NumberFormat("en-GB").format(
                                Math.round(userInfo?.total_forward_comms)
                              )}` || ""
                            }
                            InputProps={{
                              readOnly: true,
                              endAdornment: (
                                <InputAdornment position="start">
                                  <InfoIcon></InfoIcon>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Tooltip>
                      </Item>
                      <Item>
                        <TextField
                          fullWidth
                          type="text"
                          autoComplete="lifetime_bookings"
                          label="Lifetime Bookings"
                          defaultValue={
                            new Intl.NumberFormat("en-GB").format(
                              Math.round(userInfo?.lifetime_bookings)
                            ) || ""
                          }
                          InputProps={{ readOnly: true }}
                        />
                      </Item>
                      <Item>
                        <TextField
                          fullWidth
                          type="text"
                          autoComplete="lifetime_sales"
                          label="Lifetime Sales"
                          defaultValue={`£ ${
                            new Intl.NumberFormat("en-GB").format(
                              Math.round(userInfo?.lifetime_sales)
                            ) || ""
                          }`}
                          InputProps={{ readOnly: true }}
                        />
                      </Item>
                      <Item>
                        <TextField
                          fullWidth
                          type="text"
                          autoComplete="lifetime_profit"
                          label="Lifetime Profit"
                          defaultValue={`£ ${
                            new Intl.NumberFormat("en-GB").format(
                              Math.round(userInfo?.lifetime_profit)
                            ) || ""
                          }`}
                          InputProps={{ readOnly: true }}
                        />
                      </Item>
                      <Item>
                        <TextField
                          fullWidth
                          type="text"
                          autoComplete="lifetime_avb"
                          label="ABV"
                          defaultValue={`£ ${
                            new Intl.NumberFormat("en-GB").format(
                              Math.round(userInfo?.abv_lifetime)
                            ) || ""
                          }`}
                          InputProps={{ readOnly: true }}
                        />
                      </Item>

                      {userInfo?.wow_app_id && (
                        <Item>
                          <TextField
                            fullWidth
                            type="text"
                            autoComplete="wow_app_id"
                            label="WOW App ID"
                            defaultValue={userInfo?.wow_app_id}
                            InputProps={{ readOnly: true }}
                          />
                        </Item>
                      )}
                      <Item>
                        <TextField
                          fullWidth
                          type="text"
                          autoComplete="pin_level"
                          label="Pin Level"
                          defaultValue={userInfo?.current_pin_level || ""}
                          InputProps={{ readOnly: true }}
                        />
                      </Item>
                      <Item>
                        <TextField
                          fullWidth
                          type="text"
                          autoComplete="next_level"
                          label="Next Level"
                          defaultValue={userInfo?.next_pin_level || ""}
                          InputProps={{ readOnly: true }}
                        />
                      </Item>

                      <Item>
                        <TextField
                          fullWidth
                          type="text"
                          autoComplete="next_level_target"
                          label="Next Level Target"
                          defaultValue={
                            new Intl.NumberFormat("en-GB").format(
                              Math.round(userInfo?.next_pin_target)
                            ) || ""
                          }
                          InputProps={{ readOnly: true }}
                        />
                      </Item>

                      <Item>
                        <TextField
                          fullWidth
                          type="text"
                          autoComplete="still_needed"
                          label="Still Need"
                          defaultValue={
                            `£ ${new Intl.NumberFormat("en-GB").format(
                              Math.round(userInfo?.profit_required)
                            )}` || ""
                          }
                          InputProps={{ readOnly: true }}
                        />
                      </Item>

                      <Item>
                        <TextField
                          fullWidth
                          type="text"
                          autoComplete="wow_app_id"
                          label="Estimated Bookings Required"
                          defaultValue={
                            new Intl.NumberFormat("en-GB").format(
                              Math.round(userInfo?.bookings_required)
                            ) || ""
                          }
                          InputProps={{ readOnly: true }}
                        />
                      </Item>
                    </Stack>
                    <Stack
                      sx={{ margin: 2 }}
                      direction={{ xs: "column", sm: "row" }}
                      flexWrap="wrap"
                    >
                      <Item>
                        {/* <Stack spacing={-1} direction="row" sx={{ mb: 1 }} alignItems="center">
                        
                        <Avatar src={`/assets/icons/silver_transperant.png`} alt={userInfo?.current_pin_level} sx={{ zIndex: 1 }}></Avatar>
                        <Slider aria-label="Volume" value={userInfo?.sales_ytd} marks={[{
                          value: 0,
                          label: `Silver Level`,
                        },{
                          value: userInfo?.sales_ytd,
                          label: ``,
                        },
                        {
                          value: userInfo?.next_pin_target,
                          label: `Diamond Level`,
                        }]} max={userInfo?.next_pin_target} sx={{ readOnly: true,  height: '10px' }} valueLabelDisplay={`£ ${new Intl.NumberFormat('en-GB').format(userInfo?.sales_ytd)}`} />
                        <Avatar src={`/assets/icons/diamond-transperant.png`} alt={userInfo?.next_pin_level}></Avatar>
                      </Stack> */}
                      </Item>
                    </Stack>
                  </ThemeProvider>
                </div>
              )}
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default UserHome;
