import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
// @mui
import { Box, Card, CardHeader } from '@mui/material';
// utils
import { fNumber } from '../../../utils/formatNumber';
// components
import { useChart } from '../../../components/chart';
// ----------------------------------------------------------------------

AppConversionRates.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartData: PropTypes.array.isRequired,
};


export default function AppConversionRates({ title, subheader, chartData,target, isMobile, ...other }) {
  let formatterValue = Intl.NumberFormat('en', { notation: 'compact' });
  let GBFormatter = new Intl.NumberFormat('en-GB');
  const chartLabels = chartData.map((i) => i.label);
   
  const chartSeries = chartData.map((i) => i.value);
  const maxValue = ()=>{
    
    let max= Math.max.apply(Math, chartSeries);
    
    if(max<target){
      return Math.round((target*0.1 + target)/1000)*1000;
    }else{
      return Math.round((max*0.1 + max)/1000)*1000;
    }
  }
  

  const chartOptions = useChart({
    tooltip: {
      marker: { show: false },
      y: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `£ ${GBFormatter.format(Math.round(y))}`;
          }
          return y;
        },
        title: {
          formatter: () => '',
        },
      },
      
    },
    plotOptions: {
      bar: { horizontal: true, barHeight: '45%', borderRadius: 2 },
    },
   colors:'#a6738c',
    xaxis: {
      max: maxValue(),
      categories: chartLabels,
      tickAmount:isMobile?6:12,
      labels: {
        
        formatter: function (value) {
          return `£ ${formatterValue.format(value)} `;
        },
       
      }

    },
    annotations: target > 0 ? {
      xaxis: [
        {
          x: target,
          borderWidth: 1.5,
          borderColor: 'red',
          strokeDashArray: 0,
          label: {
            borderColor: '#00E396',
            
            style: {
              color: 'white',
              background: 'red'
            },
            text: `£ ${GBFormatter.format(target)} `,
            orientation: "horizontal"
          }
        }
      ]
    } :{xaxis:[]}
  });

  return (
    <Card {...other}>
      

      <CardHeader title={title} subheader={subheader} />

      <Box  dir="ltr">
        <ReactApexChart type="bar" series={[{ data: chartSeries }]} options={chartOptions} height={!isMobile ? 500 : 364} />
      </Box>
    </Card>
  );
}
