import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
// import BlogPage from './pages/BlogPage';
// import UserPage from './pages/UserPage';
import UserHome from './pages/UserHome';
import TargetCalculator from './pages/TargetCalculator';

import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
// import ProductsPage from './pages/ProductsPage';
import DashboardTC from './pages/DashboardTC';
import ForwardCommission from './pages/ForwardCommission';


// ----------------------------------------------------------------------

export default function Router() {
  let isAuth = localStorage.getItem('isAuth')|| false;
  let authToken =  localStorage.getItem('authToken') || false;
  if(!isAuth && !authToken){
  <Navigate to="/login" />
  }
  const routes = useRoutes([
    // {
    //   path: '/dashboard',
    //   element: <DashboardLayout />,
    //   children: [
    //     { element: <Navigate to="/dashboard/app" />, index: true },
    //     { path: 'app', element: <DashboardAppPage /> },
    //     { path: 'user', element: <UserPage /> },
    //     { path: 'products', element: <ProductsPage /> },
    //     { path: 'blog', element: <BlogPage /> },
    //   ],
    // },
    {
      path: '/login',
      element: (isAuth && authToken) ? <Navigate to="/createTarget" />:<LoginPage />,
    },
    {
      path: '/user',
      element: <DashboardLayout />,
      children: [
        { element: !(isAuth && authToken)?  <Navigate to="/login" />:<Navigate to="/user/home" />, index: true },
        { path: 'home', element: !(isAuth && authToken)?  <Navigate to="/login" />: <UserHome /> }
        
      ],
    },
    {
      path: '/user/targetCalculator',
      element: <DashboardLayout />,
      children: [
        { element: !(isAuth && authToken)?  <Navigate to="/login" />:<Navigate to="/user/targetCalculator/new" />, index: true },
        { path: 'new', element: !(isAuth && authToken)?  <Navigate to="/login" />: <TargetCalculator /> }
        
      ],
    },
    {
      path: '/dashboard/app',
      element: <DashboardLayout />,
      children: [
        { element: !(isAuth && authToken)?  <Navigate to="/login" />:<Navigate to="/dashboard/app/tc" />, index: true },
        { path: 'tc', element: !(isAuth && authToken)?  <Navigate to="/login" />: <DashboardTC /> }
        
      ],
    },
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: !(isAuth && authToken)?  <Navigate to="/login" />:<Navigate to="/dashboard/forwardComms" />, index: true },
        { path: 'forwardComms', element: !(isAuth && authToken)?  <Navigate to="/login" />: <ForwardCommission /> }
        
      ],
    },
    // {
    //   path: '/user',
    //   element: <DashboardLayout />,
    //   children: [
    //     { element: !(isAuth && authToken)?  <Navigate to="/login" />:<Navigate to="/user/details" />, index: true },
    //     { path: 'details', element: !(isAuth && authToken)?  <Navigate to="/login" />: <UserPage /> }
        
    //   ],
    // },
    {
      element: <SimpleLayout />,
      children: [
        { element: (isAuth && authToken)? <Navigate to="/user/" /> : <Navigate to="/login" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
