import axios from "axios";


const baseURL = process.env.REACT_APP_BACKEND_BASE_URL || "http://localhost:8080";




const getHeader = (authToken) => {
    return {
        headers: {
            Authorization: authToken
        }
    }
}

const getDateFromYearMonth = (date) => {
    date = date.toString();
    let year = date.slice(0,4);
    let month = date.slice((date.length - 2), date.length);
    
    return new Date(
        year,
        month -1 ,
        1
      );
  };

//axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('authToken')}`;

export const validateSession = (authToken, setUserDetails) => {
    axios.get(`${baseURL}/validate_session/`, getHeader(authToken)).then((response) => {
        setUserDetails(response.data);
       
    }).catch((error) => {
        console.log(`Error in validating | response :`, error);
        if(error?.response?.status===401){
        setUserDetails({error:true,errorMessage:"You are not authorized to Access this application. Please contact System Administrator"});}
        else{
            setUserDetails({error:true,errorMessage:"Unexpected System Error. Please contact System Administrator"});}
        
    });

}

export const getOverAllSummaryForUser = ( setUserInfo) => {
    
    let authToken = localStorage.getItem("authToken");
    
    axios.get(`${baseURL}/get_tc_summary/?monthly=false`,getHeader(authToken)).then((response) => {
       setUserInfo(response.data[0]);
        
    }).catch((error) => {
        console.log(`Error in validating | response :`, error);
        if(error?.response?.status===401){
           
            setUserInfo({error:true,errorMessage:"You are not authorized. Please contact System Administrator", errorCode:401});}
        else{
            setUserInfo({error:true,errorMessage:"Unexpected System Error. Please contact System Administrator", errorCode:error?.response?.status});}
        
    });

}

export const getOverAllSummaryForUserMonthly = ( startDate, endDate,setOverviewSummaryMonthly) => {
    
    let authToken = localStorage.getItem("authToken");
    
    axios.get(`${baseURL}/get_tc_summary/?_from=${startDate}&_to=${endDate}&monthly=true`,getHeader(authToken)).then((response) => {
        response?.data[0]?.monthly_aggregates?.map((d)=>{d.year_mon = getDateFromYearMonth(d.year_mon); return d;}).sort((a, b) => { return a?.year_mon?.getTime() - b?.year_mon?.getTime(); });
        response?.data[0]?.monthly_aggregates?.sort((a, b) => { return a?.year_mon?.getTime() - b?.year_mon?.getTime(); });
        setOverviewSummaryMonthly(response.data[0]);
        
        
    }).catch((error) => {
        console.log(`Error in validating | response :`, error);
        if(error?.response?.status===401){
           
            setOverviewSummaryMonthly({error:true,errorMessage:"You are not authorized. Please contact System Administrator", errorCode:401});}
        else{
            setOverviewSummaryMonthly({error:true,errorMessage:"Unexpected System Error. Please contact System Administrator", errorCode:error?.response?.status});}
        
    });

}

export const getForwardComissionSummaryForUser = ( setUserCommissionInfo) => {
    
    let authToken = localStorage.getItem("authToken");
    
    axios.get(`${baseURL}/get_tc_forward_comms/`,getHeader(authToken)).then((response) => {
        response?.data[0]?.comms?.monthly_comms?.map((d)=>{d.year_mon = getDateFromYearMonth(d.year_mon); return d;});
    
       setUserCommissionInfo(response?.data[0]?.comms);
    }).catch((error) => {
        console.log(`Error in validating | response :`, error);
        if(error?.response?.status===401){
           
            setUserCommissionInfo({error:true,errorMessage:"You are not authorized. Please contact System Administrator", errorCode:401});}
        else{
            setUserCommissionInfo({error:true,errorMessage:"Unexpected System Error. Please contact System Administrator", errorCode:error?.response?.status});}
        
    });

}