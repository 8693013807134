import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
// @mui
import { Card, CardHeader, Box } from '@mui/material';
// components
import { useChart } from '../../../components/chart';
import { format } from 'date-fns';

// ----------------------------------------------------------------------

AppWebsiteVisits.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartData: PropTypes.array.isRequired,
  chartLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default function AppWebsiteVisits({ title, subheader, chartLabels, chartData, label, prefixandsuffix, isMobile,...other  }) {
  let formatterValue = Intl.NumberFormat('en-GB', //{ notation: 'compact' }
  );
 
  const chartOptions = useChart({
    colors : ['#b84644', '#4576b5'],
  
    plotOptions: { bar: { columnWidth: '30%' },  distributed: true },
    fill: { type: chartData.map((i) => i.fill) },
    labels: chartLabels,
    xaxis: { //type: 'datetime' ,
      tickAmount:isMobile?(chartLabels?.length<12?chartLabels?.length-1:6):(chartLabels?.length+1)||12,
      labels: {
        formatter: (x) => {
          // if (typeof x !== 'undefined') {
          //   return `${format(x, "MMM,yy")}`;
          // }
          return x;
        }
      }
  },
    
    yaxis: {
      labels: {
        formatter: function (value) {
          return `${formatterValue.format(value)}`;
        }
      },
    },
    tooltip: {
       shared: false,
      // intersect: false,
      x: {
        show:false,
        // formatter: (x) => {
        //   if (typeof x !== 'undefined') {
        //     return `${format(x, "MMM,yy")}`;
        //   }
        //   console.log(x)
        //   return x;
        // },
      },
      y: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `${formatterValue.format(y)}`;
          }
          return y;
        },
      },
    },
  });

  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <ReactApexChart type="line" series={chartData} options={chartOptions} height={!isMobile ? 364 : 300} />
      </Box>
    </Card>
  );
}
